import './monthPicker.less';

/*
setting: {
    container: null,
    startYear: '', // 開始年份 String
    endYear: '',   // 結束年份 String
    monthNames: [] // 自訂月份名稱 (例如使用其他語言的月份名稱)
}
*/
export class monthPicker {

    constructor(setting = {}){

        this.setting = setting;
        if(!this.setting.container){
            console.log('container not found!');
            return;
        }

        this.container = this.setting.container;

        const today = new Date(); // 今天
        this.currentYear = today.getFullYear(); // 當年   
        // 當月，getMonth 取得的數值從 0 開始 (0~11)，所以需要 + 1
        this.currentMonth = today.getMonth() + 1;

        // 開始年份，如果沒有傳入，則指定為 2022 年
        this.startYear = (this.setting.startYear)? this.setting.startYear : '2022';
        // 結束年份，如果沒有傳入，則指定為今年
        this.endYear = (this.setting.endYear)? this.setting.endYear : this.currentYear;

        this.pickYear = this.currentYear; // 目前選定的年，預設為當年
        this.pickMonth = this.currentMonth; // 目前選定的月 1~12，預設為當月

        // 不管語言別都顯示的英文月份縮寫
        this.altMonthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
        ]

        this.init();   
        this.events();
    }

    /* ----- getter: 取得目前選定的年月值 ----- */
    // ex: MonthPicker.pick
    get pick(){

        const Year = this.pickYear;
        const Month = this.pickMonth;    

        let text = '';
        if(`${Month}`.length < 2){
            text += `${Year}-0${Month}`;
        } else {
            text += `${Year}-${Month}`;
        }

        const data = {
            year: parseInt(Year, 10), // ex: 2023 (數字)
            month: parseInt(Month, 10), // ex: 6 (數字)
            text: text // ex: 2023-06 (字串)
        }
        return data;
    }

    init(){

        this.container.classList.add('month-picker');

        let yearDisabled = '';
        const startYear = parseInt(this.startYear, 10);
        const endYear = parseInt(this.endYear, 10);
        if(startYear === endYear){
            // 開始年跟結束年相同時，不顯示左右按鈕
            yearDisabled = ' disabled';
        }

        // 建立月份選擇區塊
        let monthBricks = '';
        const monthNames = this.setting.monthNames;
        const altMonthNames = this.altMonthNames;
        const pickMonth = parseInt(this.pickMonth, 10) - 1;
        monthNames.forEach((item, index) => {

            let selected = '';

            if(index == pickMonth){
                selected = ' selected';
            }

            monthBricks += 
            `<div class="month-brick${selected}" data-month="${index + 1}">
                <span class="main">${item}</span>
                <span class="alt">${altMonthNames[index]}</span>
            </div>`;
        });

        const html = 
        `<div class="picker-overlay"></div>

        <div class="picker-label">
            <p class="picker-label-txt">
                <span class="year">${this.currentYear}</span>年<span class="month">${this.currentMonth}</span>月
            </p>
            <svg>
                <use xlink:href="/assets/sprites/sprites_solid.svg#angle-down"></use>
            </svg>
        </div>

        <div class="picker-content">
            <div class="yearwpr${yearDisabled}">
                <div class="pick-year-sub picker-arrow">
                    <svg><use xlink:href="/assets/sprites/sprites_solid.svg#angle-left"></use></svg>
                </div>
                <div class="pick-year">${this.pickYear}</div>
                <div class="pick-year-add picker-arrow">
                    <svg><use xlink:href="/assets/sprites/sprites_solid.svg#angle-right"></use></svg>
                </div>
            </div>
            
            <div class="monthwpr">
                ${monthBricks}
            </div>
        </div>`;

        this.setting.container.innerHTML = html;

    }

    /* ----- 增加或減少年份 ----- */
    yearChange(action){
        // action: 'add' || 'sub'

        const startYear = parseInt(this.startYear, 10);
        const endYear = parseInt(this.endYear, 10);

        let year = parseInt(this.pickYear, 10);
        
        if(action === 'add'){
            year += 1;
            if(year > endYear){
                year = endYear
            }
        }

        if(action === 'sub'){
            year -= 1;
            if(year < startYear){
                year = startYear
            }
        }

        this.pickYear = year;
        this.container.querySelector('.pick-year').textContent = year;       
        this.container.querySelector('.picker-label-txt .year').textContent = year; 
    }

    /* ----- 選擇月份 ----- */
    monthChange(month){
        this.pickMonth = month;

        const bricks = this.container.querySelectorAll('.month-brick');
        bricks.forEach((item) => {
            if(item.dataset.month === month){
                item.classList.add('selected');
            } else {
                item.classList.remove('selected');
            }
        });

        this.container.querySelector('.picker-label-txt .month').textContent = month;

        // 關閉選單
        this.container.classList.remove('expand');
    }


    events(){

        this.container.addEventListener('click', (event) => {     

            if(event.target.classList.contains('picker-label')){        
                const container = this.container;
                if(container.classList.contains('expand')){
                    // 關閉選單              
                    container.classList.remove('expand');
                } else {
                    // 開啟選單             
                    container.classList.add('expand');
                }
            }

            if(event.target.classList.contains('picker-overlay')){          
                // 關閉選單        
                this.container.classList.remove('expand');
            }

            // 減少年份
            if(event.target.classList.contains('pick-year-sub')){
                this.yearChange('sub');
            }

            // 增加年份
            if(event.target.classList.contains('pick-year-add')){
                this.yearChange('add');
            }

            // 選擇月份
            if(event.target.classList.contains('month-brick')){
                this.monthChange(event.target.dataset.month);
            }


        

        });


    }


}