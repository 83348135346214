// {variable} 代表變數字串，會用程式帶入值
const text = {

    // ----- API ----- //
    apiMsg: {
        sending: '資料傳送中',
        querying: '查詢中...',
        error: {
            common: '資料讀取錯誤',
            error1: {
                msg: '資料載入錯誤，錯誤代碼：{variable}',
                tips: '請按確認重新整理頁面'
            },
        }
    },

    template: {
        // 尚未載入資料時的空白內容提示
        noContent: {
            msg: '歡迎使用銓勝酒測平台',
            tips: '尚未選取公司或站點'
        }
    },

    language: {
        tc: '中文',
        jp: '日本語',
        en: 'English'
    },

    /* ----- terms / privacy page links ----- */
    privacy: [
        {
            title: 'Privacy Policy',
            link: '/user/rules',
            newTab: true
        },
        {
            title: 'Terms of Service',
            link: '/user/terms',
            newTab: true
        }
    ],

    /* ----- footer ----- */
    footer: {
        title: 'FITZ INC.',
        address: 'No. 358-7, Dong Sec. 2, Guangming 6th Rd., Zhubei City, Hsinchu County',
        email: 'service@careexpert.life',
        links: [
            {
                title: 'FITZ INC.',
                link: 'https://www.careexpert.life/',
                newTab: true
            },
            {
                title: 'CareExpert',
                link: '/',
                newTab: true
            },
            {
                title: 'Aceto Ketone Meter',
                link: 'https://www.aceto.io/',
                newTab: true
            }
        ]
    },

    /* ----- 主選單 ----- */
    nav: {
        coms: 'Companies',
        sites: 'Sites',
        edges: 'APP',
        devices: 'Devices',
        managers: 'Credentials',
        testers: 'Testers',
        records: 'Records',
        info: 'Dashboard',
        inspection: '檢核表'
    },

    /* ----- 資料庫欄位名稱對照 (按字母順序) ----- */
    fieldName: {
        active: 'Condition',
        addr: 'Address',
        alcohol_value: 'Blood alcohol content (Raw)', // 酒測值，未經調整的原始數值

        bind_to: '對應的帳號',
        bp_dia_value: 'Diastolic pressure',
        bp_sys_value: 'Systolic pressure',
        bp_dia_high_limit: '舒張壓 Max',
        bp_dia_low_limit: '舒張壓 Min',
        bp_sys_high_limit: '收縮壓 Max',
        bp_sys_low_limit: '收縮壓 Min',

        bp_required: 'Blood Pressure Required',
        brand: 'Brand',
        buildnumber: 'Build',
        business_no: 'Tax ID number',

        company_custom: 'Company custom',
        company_id: 'Company',
        company_sname: 'Company shortname',
        contract_started_at: 'Contract start date',
        contract_ended_at: 'Contract end date',
        created_at: 'Add date',
        device_id: 'Device ID',
        devicemodel_name: 'Model',
        edge_id: 'Edge ID',
        edge_sequence: 'Edge sequence',
        edge_type: 'Type',
        email: 'Email',
        employee_number: 'Employee number',
        error_value: '紅色警示',
        face_verify_result: 'Face verification',
        fax: 'Fax',
        fee_at: '每月請款日',
        final_value: 'Blood alcohol content', // 酒測值，經過調整後的數值
        gain: 'Gain',
        geojson: 'Location',
        heating_count: 'Heating',

        hr_value: 'Heart rate',
        hr_high_limit: 'Heart rate Max',
        hr_low_limit: 'Heart rate Min',

        id: 'ID',
        id_card_number: 'ID card number',
        image: 'Photo',
        installed_at: 'Installed',
        legal_value: 'Legal limit',
        licensed_at: 'Licensed',
        license_id: 'License ID',
        license_key: '授權碼',
        limit_count: 'Maximum number of sobriety tests',
        log: 'Log',
        memo: 'Note',
        mobile: 'Mobile number',
        model: 'Model',
        multiuser_mode: '授權模式',
        name: 'Name',
        name_alt: 'Title', // name 有可能非人名，而是裝置名稱，例如 edge 頁
        offset: 'Offset',
        operation_type: '標籤',
        osbuild: 'OSBuild',
        override_require_vehicle_info: 'APP 禁止略過車號輸入',
        pwm_for_engineering: 'PWM',
        recordable_type: 'Subject type',
        result: 'Result',
        rfid_identifier: 'RFID',
        route_type: '標籤群組',
        serial_no: 'Serial number',
        site_custom: 'Customized site',
        site_id: 'Site id',
        site_sname: 'Site shortname',
        slope_ratio: 'Slope',
        sname: 'Test site',
        supervisors: 'Supervisor',
        tel1: 'Telephone',
        test_count: 'Times of use',
        test_status: 'status',
        tested_at: 'Time',
        tester_menu: 'Identification',
        tester_sname: 'Preparation site',
        types: 'Management authority',
        uniqueid: 'UniqueID',
        updated_at: 'Last Update',
        user_id: 'UserID',
        vehicle_id_number: 'Vehicle id number',
        version: 'Version',
        warning_value: '橘色警示',
        working_hours: 'Working hour', // 暫時用這個 key 值

        // 工程用
        cal_nogas_adc: 'calNoGasADC',
        test_nogas_adc: 'testNoGasADC',
        test_min_adc: 'testMinADC',
    },

    /* ----- 前端自定義的欄位名稱對照 ----- */
    fieldNameCustom: {

        // 公司相關
        company: {
            name: 'Full name',
            sname: 'Display name',
            types: '公司類型',

            _address: 'Address',
            _bp_dia: 'Diastolic pressure allowance',
            _bp_sys: 'Systolic pressure allowance',
            _contract: 'Start and end dates of the contract',
            _distributor: 'Distributor ID',
            _hr: 'Hear rate allowance',
        },

        // 站點相關
        site: {
            name: 'Full name',
            sname: 'Display name'
        },

        _adc_ratio: 'test/cal',
        _appNote: 'Condition',
        _bloodPressure: 'Blood pressure',
        _cumulativeCount: '雲端使用次數',
        _detail: 'Detail',
        _face: 'Face', // 受測者列表
        _managerNote: '自訂備註', // 管理權限備註
        _usage: '使用紀錄', // 在 devices 中查詢「裝置轉移紀錄」、「最後幾筆量測紀錄」
        _tester: 'Tester', // 在 edge 列表中顯示受測者資料
        bindAccount: 'Bind account', // 管理權限表 th
        licenseKey: 'Authorization code',

        // 未通過時的處理
        _record_statusId: '超標處理',
        _record_statusNote: '處理備註'
    },

    /* ----- 將欄位值代碼 (例如 0, -1) 轉換為文字 ----- */
    fieldParse: {

        // 裝置狀態 (括號中是舊版使用的名稱)
        // 這邊的名稱如果變動，裝置月報表 deviceUsageReport 中的名稱也需要調整
        active: {
            '0': 'Enabled', // ENABLED
            '1': 'Active', // ACTIVE
            '2': 'Spare', // SPARE
            '3': 'Sample', // SAMPLE
            '-1': 'Disabled', // DISABLED
            '-2': 'Deleted' // DELETED
        },

        // 綁定管理權限
        bindAccount: {
            bind: 'bound',
            unbound: 'Unbound'
        },

        // devicemodels/list 'type' 值
        deviceModelType: {
            '0': 'Others',
            '1': '有線酒測器',
            '2': '無線藍牙酒測器',
            '3': 'Blood pressure monitors',
            '4': 'Thermometer',
        },
        // device model 類別的排列順序
        deviceModelOrder: [
            2, 1, 3, 4, 0, 8
        ],

        // 批次新增或變更裝置狀態
        // API: '/devices/batch' 中 'op_type' 的參數值定義
        // 對應裝置狀態的 active 值
        deviceOpType: {
            'Add': 'Add',
            'Spare': 'Spare',
            'Sample': 'Sample',
            'Suspend': 'Suspend',
            'Delete': 'Delete',
        },

        // edge_type Edge 類型
        edgeType: {
            '1': 'Windows',
            '2': 'Wireless breath analyzer',
            '3': 'Wired breath analyzer',
            '4': 'Wireless sphygmomanometer',
            '5': 'Punch clock',
            'null': 'Unknown'
        },

        // Edge 的授權模式
        multiuserMode: {
            '0': 'Single',
            '1': 'Multi-user',
            '2': 'Multi-user'
        },

        // 酒測結果
        result: {
            '0': 'Failed',
            '1': 'Pass',
            '-1': 'Insufficient blowing',
            '-2': 'Not completed',
            '-3': 'Time error'
        },

        // 超標處理狀態
        recordStatus: {
            '1': '取消',
            '2': '誤判',
            '3': '重測',
            '4': '直接出車',
            '5': '代班',
            '6': '其他',
        },

        // 人臉驗證結果
        faceVerifyResult: {
            '-1': '未啟用',
            '-2': 'Face API 異常',
            '0': '通過',
            '1': '未通過',
            '2': '未偵測到人臉',
            '3': '偵測到多個人臉',
            '4': '未建立人臉',
            '5': '群組不存在',
            '6': '搜尋人員失敗',
            '7': '搜尋人臉失敗',
            '8': '無網路'
        },

        // APP 禁止略過車號輸入
        overrideRequireVehicleInfo: {
            'default': '按公司設定', // null
            'on': '開啟', // true
            'off': '關閉' // false
        }

    },

    /* ----- 公司列表頁 ----- */
    pageComs: {

        list: {
            none: '無',
            notset: '未設定',
            feeRequest: '待請款'
        },

        button: {
            addCom: 'Add New',
            addTemplate: 'Add New (Shortcut)',
            editCom: 'Basic Info',
            setting: 'Setting',
            showLicense: 'Licenses',
            delete: 'Archive',
            reverse: 'Retrieve'
        },

        types: {
            '1': 'Direct sale customer', // 直營客戶
            '2': 'Distributor', // 經銷商
            '3': 'System integrator', // 系統整合商
            '4': 'Distribution customer' // 經銷客戶
        },

        // 身份辨識方式
        tester_menu: {
            '1': 'Mobile number',
            '2': 'Employee number',
            '3': 'ID card number'
        },

        // 進階設定
        configBox: {
            // APP 使用模式設定值對照表
            appDefaultBindtesterTable: [
                {value: 1, title: '單人'},
                {value: 2, title: '多人'},
                {value: 3, title: '由 APP 設定'},
            ],
            // 欄位名稱
            fieldName: {
                // 主要服務
                'alcohol_enabled': '酒測器',
                'bp_hr_enabled': '血壓計',
                'punch_in_enabled': '打卡',

                // 自訂圖檔
                'bg_enable': '自訂酒測機背景圖',
                'notice_enable': '自訂酒測機公告圖',

                // 其他欄位按字母排序
                'app_default_bindtester': 'APP 使用模式',

                'custapipull_enable': 'PULL Service APIs',
                'custapi_secret': 'API 密碼',

                'enable_check_notification': 'APP 自動檢查並提醒<br />用戶端開啟通知功能',
                'enable_guest_mode': '強制使用訪客模式',
                'enable_qrcode_default': '預設使用非文字身分識別<br /><small>(例如 QR code 或條碼)</small>',
                'enable_show_route': '訪客模式：顯示路線',
                'enable_show_vehicle_id_number': '訪客模式：顯示車號',
                'enable_vehicle_info': 'APP 車號輸入',

                'face_recognition_enabled': '人臉辨識<br /><small>Face Recognition</small>',
                'face_verification_enabled': '人臉驗證<br /><small>Face Verification</small>',

                'require_vehicle_info': 'APP 禁止略過車號輸入',

                'schedule_enabled': '班表',
                'stopLimitEN': '酒測器禁用偵測<br /><small>禁用次數 {variable}</small>',
            },
            // 選項名稱
            options: {
                'on': '開啟',
                'off': '關閉'
            },
            // 選項名稱 alternate
            optionsAlt: {
                'on': '啟用',
                'off': '停用',
            },
            // 文字說明
            textContent: {
                // 主要服務的自訂標籤說明
                alcoholOptions: '酒測自訂標籤<small> ( 可設定 4 組，每組 4 個字以內 )</small>',
                bpHrOptions: '血壓自訂標籤<small> (可設定 4 組，每組 4 個字以內)</small>',
                punchInOptions: '打卡自訂標籤<small> ( 可設定 4 組，每組 4 個字以內 )</small>',
                // CustAPI 密碼規則
                apiSecretRule: '* 密碼必須為 8~16 個字元，數字加英文字母',
                // route type 標籤群組
                routeTypesLabel: '設定標籤群組<br /><small>( 可自訂群組名稱 )</small>',
                routeTypesPlaceholder: '例如：溫層'
            },
            // 操作訊息
            message: {
                custapiSecretInvalid: 'API 密碼格式錯誤',
                routeLabelMissing: '請設定標籤群組名稱',
                success: '設定已更新',
                success64: '',
                // 缺少圖檔
                imgBgMissing: '請上傳酒測機背景圖',
                imgNoticeMissing: '請上傳酒測機公告圖',
                // 設定更新失敗
                failUpdatecfg: '設定更新失敗',
                failUpdatecfg64: '圖檔更新失敗'
            }
        },

        // 授權碼操作
        licenseBox: {

        },

        // 新增或編輯公司資料
        slidebox: {
            success: '資料更新完成',
            error: {
                business_no: '系統中已存在相同的統編',
            }
        },

        // 建立公司範本
        batchbox: {
            title: '建立公司範本',
            hint: '請輸入以下基本資料，系統將依序建立「公司、站點、管理權限、受測者」，稍後您可再手動修改資料內容',
            field: {
                bind_to: '管理帳號',
                business_no: '統編',
                distributor: '經銷商',
                name: '公司全名',
                sname: '顯示名稱',
                types: '公司類型'
            },
            // 預設值
            default: {
                siteName: '總站', // 站點名稱
                siteSName: '總站', // 站點簡稱
                managerNote: 'ADMIN', // 管理權限備註
                testerName: '預設受測者', // 受測者姓名
                testerNumber: '0000', // 受測者工號
            },
            btn: {
                submit: '開始',
                close: '關閉'
            },
            validation: {
                business_no: '只限英文字母與數字',
                noDistributor: '請選擇經銷商',
                required: '請輸入完整資料',
            },
            success: {
                company: '已建立公司：{variable}',
                site: '已建立站點：{variable}',
                manager: '已建立管理權限：{variable}',
                tester: '已建立受測者：{variable}',
                finish: '建立完成，您可繼續建立下一筆'
            },
            fail: {
                business_no: '系統中已存在相同的統編',
                company: '公司建立失敗'
            }
        }

    },

    /* ----- 站點列表頁 ----- */
    pageSites: {

        list: {
            none: 'None',
        },

        view: {
            info: 'Site information',
            sorting: 'Today data'
        },

        info: '{name}：{data}', // 例如 站點自訂：無

        fastdial: {
            sum: '{variable} site',
            placeholder: 'Enter a site name'
        },

        control: {
            site: {
                add:'Add New',
                edit: 'Basic Info',
                delete: 'Delete',
                delConfirm: 'Are you sure to delete this site?',
                inventorySetting: '庫存設定'
            },
            note: {
                add:'Add',
                edit: 'Edit',
                delete: 'Delete',
                delConfirm: 'Are you sure to delete this note?'
            },
        },

        // 站點備註
        noteDialog: {
            hint: '此備註專屬於您<strong>目前使用中的管理權限</strong>，您無法看到其他人或其他管理權限建立的備註內容'
        }

    },

    /* ----- Edges ----- */
    pageEdges: {

        sum: '共 {variable} 個 APP 用戶端',

        showSingleModeList: {
            btn: '顯示所有單人用戶端',
            hint: '列出此公司旗下所有授權模式為單人的用戶端<br />( 較新版本的單人模式 APP 用戶端無隸屬站點 )'
        },

        btn: {
            queryUsage: '查詢', // 查詢使用紀錄
        },

        // 受測者欄位，hover 顯示更多資料
        tester: {
            employeeNumber: '員編：',
            mobile: '手機：'
        },

        // 查詢使用紀錄
        usageQuery: {
            title: '最近量測紀錄',
            noData: '無最近量測紀錄'
        }

    },

    /* ----- Devices ----- */
    pageDevices: {

        list: {
            searchKey: '搜尋條件：{variable}', // 顯示所輸入的搜尋條件

        },

        btn: {
            setting: 'Setting',
            queryUsage: '検索',
        },

        // 裝置設定
        setting: {
            title: '裝置設定',
            action: {
                // 轉移到其他站點
                transfer: {
                    title: '轉移到其他站點'
                },
                // 變更序號
                serialNo: {
                    title: '變更序號 (此欄位留空表示不變更)',
                    placeholder: '請輸入新的序號'
                },
                // 變更狀態
                active: {
                    title: '變更狀態'
                }
            },
            fieldTitle: {
                id: 'ID：',
                modelName: '型號：',
                serialNo: '序號：',
                site: '站點：',
                status: '狀態：',
                licenseKey: '授權碼：',
            },
            msg: {
                m01: '未更新任何設定',
                m02: '請選擇站點',
                m03: '確定註銷此裝置嗎？',
                m04: '更新失敗，請確認序號是否重複了',
                m05: '設定完成',
            }
        },

        // 批次新增
        storeBatch: {
            title: '批次新增：{variable}',
            btn: {
                cancel: 'Cancel',
                close: 'Close',
                confirm: 'Submit',
                trigger: '批次新增',
                tips: {
                    noSite: '需選擇站點'
                }
            },
            hint: '請選擇裝置型號；如果要一次新增多筆資料，請將完整序號以<strong>半形空白</strong>隔開',
            sum: '數量：{variable}',
            msg: {
                emptyInput: '請輸入序號',
                foundExist: '發現重複的裝置',
                invalidInput: '無效的序號',
                invalidModel: '請選擇裝置型號',
                success: '成功新增 {variable} 個裝置',
                successTransfer: '成功轉移 {variable} 個裝置',
            }
        },

        // 其他批次操作 (停用 suspend / 回收 delete)
        batchAction: {
            title: '批次設定：{variable}',
            btn: {
                close: 'Close',
                confirm: 'Submit',
                trigger: '批次設定',
                tips: '可跨公司站點操作'
            },
            hint: '將不同序號以空白隔開，可批次處理多筆資料',
            sum: '數量：{variable}',
            msg: {
                success: '設定完成',
                invalidInput: '無效的序號',
                failed: '批次設定失敗',
                failedSN: '錯誤的序號：{variable}',
                failedUpdate: '設定失敗：{variable}'
            }
        },

        // 搜尋
        search: {
            placeholder: 'Search for devices',
            remark: 'Please enter the serial number for the query, the device can support single fuzzy query or multiple precise query<br />For multiple queries, please enter the whole serial number and separate the different serial numbers by blanks.<br />多筆查詢請輸入完整序號，並將不同序號以空白隔開',
            company: 'Company',
            error: {
                noCompany: '尚未選擇公司'
            }
        }
    },

    /* ----- Managers ----- */
    pageManagers: {
        button: {
            add: 'Add authorization',
            edit: 'Edit authorization',
            bindExplain: 'Binding description',
            tips: {
                noSite: '需選擇站點'
            }
        },

        panel: {
            add: 'Add authority: {variable}',
            edit: 'Edit authority: {variable}',
        },

        message: {
            bindAccount: 'The administrative authority can only be activated after the device is bound with the account.',
            supervisorsHint: 'Please enter the supervisor\'s employee number and if entering more than one, please separate by a semi-comma.'
        },

        bindExplainBox: {
            title: 'Instructions of binding administrative authority and account',
            btn: {
                close: 'Close',
                bindExplain: '綁定說明'
            }
        },

        bindHintBox: {
            title: '已新增管理權限',
            titleAlt: '權限綁定說明',
            desc:
            `<p>管理權限建立後會自動關聯對應的雲端平台帳號</p>
            <p>{variable}</p>
            <ul>
                <li>如果此帳號已註冊過，將自動完成綁定</li>
                <li>若尚未註冊，請通知指定對象使用此 Email 註冊並登入雲端平台，即可完成綁定</li>
            </ul>`,
            notSet: '尚未設定對應的雲端平台帳號'
        },

        delete: {
            alert1: 'Are you sure to delete this administrative authority?',
            alert2: 'This is your current administrative authority. Are you sure o delete it?'
        },

        // 根據公司類型，限制可新增的管理權限
        // 注意此處的 Object key 值代表的是公司類型 (company types)
        // Array 中的值代表的是管理權限
        companyRules: {
            1: [3, 4], // 直營客戶 com, sm
            2: [2, 3, 4], // 經銷商 dm, com, sm
            3: [2, 3, 4], // 系統整合商 dm. com, sm
            4: [3, 4] // 經銷客戶 com, sm
        },

        // 管理權限數字轉換 (同 account.roles)
        roleCode: {
            '1': 'cem',
            '2': 'dm',
            '3': 'com',
            '4': 'sm',
            '5': 'tm',
            '6': 'regular',
        }
    },

    /* ----- Testers ----- */
    pageTesters: {
        // ex: 共 12 位受測者
        sum: 'total {variable} subjects',

        list: {
            btn: {
                edit: '編輯',
                delete: '刪除',
                transfer: '轉移',
            }
        },

        button: {
            add: 'Add subjects',
            edit: 'Edit subjects',
            close: 'Close',
            renewDbExplain: 'How to update the database manually',
            tips: {
                noSite: '需選擇站點'
            }
        },

        panel: {
            add: 'Add subjects: {variable}',
            edit: 'Edit subjects: {variable}',
        },

        placeholder: {
            vehicleIdNumber: '車號範例：ABC-5678'
        },

        renewDbHintBox: {
            title: 'Subject added',
            desc: 'If you are using a mobile APP or Windows PC software, the subjects added this time will take effect after <strong> has manually updated the database </strong> or <strong> has reactivated APP / Windows software.'
        },

        renewDbExplainBox: {
            title: 'Update the mobile APP or Windows PC software manually'
        },

        search: {
            placeholder: 'Search for subjects',
            hint: 'Search for subjects by keyword. All subjects from the selected company<br /> can be found. Press the button Enter button or &#10132;  to start the search',
            condition: {
                type: 'Type',
                company: 'Company'
            },
            error: {
                nocompany: '需選擇公司',
                noinput: '未輸入關鍵字',
                notFound: '沒有符合的受測者',
                apiError: '發生錯誤，請稍後再試'
            },
            prefixTag: '搜尋結果'
        },

        batchStore: {
            trigger: 'Batch add',
            box: {
                title: 'Batch add subjects: {variable}',
                placeholder: 'Example of input format: \r\nJohn 12345678\r\nAlice 87654321',
                previewTitle: 'The subjects have been input (maximum 200 subjects)',
                input: {
                    name: 'Name',
                    type: '{type}',
                    vehicle: 'Vehicle Number',
                    missing: 'No input found',
                    colon: ':' // 冒號
                },
                tips: {
                    t1: '受測者的{name}與{type}為必填，可選填車號',
                    t2: '請以「空白」區隔不同欄位，以「Enter鍵換行」區分不同受測者 (英文會自動轉換為大寫)',
                    t3: 'Excel 提示',
                },
                submit: 'Submit' // 確定送出按鈕
            },
            success: 'Add  {variable} subjects successfully',
            error: {
                custapiDisabled: {
                    title: 'PULL Service APIs 未開啟',
                    content: '請聯繫您的供應商開通此功能'
                },
                noinput: '尚未輸入內容',
                incomplete: '輸入的資料不完整'
            }
        },

        // 人臉辨識
        face: {
            title: '上傳受測者照片：{variable}',
            info: {
                company: '公司：',
                site: '站點：',
                name: '姓名：',
                number: '工號：',
                desc: '本系統不會儲存上傳的照片'
            },
            button: {
                invalid: '--',
                valid: '更新',
            },
            msg: {
                authAlert: '需「公司管理」或「站點管理」權限',
                uploadSuccess: '圖片上傳成功',
                uploadFail: '圖片上傳失敗',
            }
        },

        // 轉移受測者
        transfer: {
            desc: 'Transfer the subjects to other sites',
            error1: {
                noDestination: '尚未選取公司或站點',
                sameSite: '目的站點與原站點相同'
            },
            success: 'Transferred successfully'
        },

        bpRequired: {
            'true': 'Yes',
            'false': '--'
        },

        // 新增或編輯受測者資料
        slidebox: {
            success: '資料更新完成',
            error: {
                employee_number: '重複的員工編號',
            }
        },

        message: {
            deleteTester: '確定要刪除嗎？',
        }

    },

    /* ----- Records ----- */
    pageRecords: {

        panel: {
            date: {
                label: '日期：',
                today: '今日',
                yesterday: '昨日',
                thisweek: '本週',
                thismonth: '本月',
                lastmonth: '上月',
                range: '區間',
                rangePlaceholder: '選擇日期區間',
            },
            siteType: {
                label: '多人模式受測者：',
                test: {
                    label: '在此站量測',
                    desc: '包含訪客等所有人員，在此站點的量測記錄'
                },
                org: {
                    label: '編制在此站',
                    desc: '組織編制在此站點的人員量測記錄，量測站點可能為外站'
                },
                all: {
                    label: 'All'
                }
            },
            tester: {
                name: {
                    label: '姓名',
                    desc: '請輸入姓名 (可不完整)',
                },
                employeeNumber: {
                    label: '員工編號',
                    desc: '請輸入員工編號 (需完整)',
                }
            },
            others: {
                failedOnly: '只看未通過',
                unfinished: '包含未完成'
            },
            button: {
                createInspection: '建立檢核表',
                exportExcel: 'Export Excel',
                operation: '班次統計',
                showMap: 'Map',
                submit: '開始查詢',
                tab_crosscompany: '委外',
                tab_measure: '量測紀錄',
                tab_punch: '打卡紀錄',
            },
            // 此次查詢的查詢條件，顯示冒號
            queryCondition: {
                date: '日期：',
                name: '姓名：',
                employeeNumber: '員編：',
                resultFailed: '未通過'
            }
        },

        message: {
            emptyContent: '設定條件後請按「開始查詢」',
        },

        error: {
            msg1: '找不到量測紀錄，請試試其他查詢條件',
            msg2: '資料格式錯誤，請試試其他查詢條件',
        },

        crossCompanyFilter: {
            // 可以看到「委外」按鈕的公司 ID 白名單
            whiteList: {
                TW: [429], // #429 奇美實業
            }
        }
    },

    /* ----- 資訊統計頁 ----- */
    pageInfo: {

        counts: {
            title: 'Quantity Statistics',
            fieldName: {
                // 欄位名稱對照
                'company-counts': '公司',
                'device-counts': '啟用裝置',
                'distributor-counts': '經銷商',
                'edge-counts': 'APP 用戶端',
                'manager-counts': '管理權限',
                'record-counts': '量測記錄',
                'site-counts': '站點',
                'tester-counts': '受測者'
            },
            map: [
                // 欄位顯示順序，根據權限不同，不一定每一項都有
                'distributor-counts',
                'company-counts',
                'site-counts',
                'edge-counts',
                'manager-counts',
                'tester-counts',
                'device-counts',
                'record-counts'
            ],
            noData: '尚無統計資料'
        },

        device: {
            title: '裝置狀態總覽',
            // remark: '使用次數超過 1,800 次酒測器列表',
            message: {
                loading: '資料載入中...',
                fail: '資料載入失敗，請稍後再試',
                // notFound: '沒有使用超過 1,800 次的酒測器'
            }
        }

    },

    // ----- 使用者登入後 ----- //
    user: {
        error: {
            msg1: '使用中的權限發生錯誤，無效的公司或站點，請切換其他權限或綁定新權限'
        }
    },

    /* ----- 使用者註冊、登入、帳號密碼、使用者選單、管理權限 ----- */
    account: {
        login: {
            welcome: '酒測助手管理平台',
            slogan: 'Automatic photography, cloud management, easy to understand the state of sobriety tests.',
            socialLogin: {
                google: 'Login with Google',
            },
            email: 'Email Login',
            loginBtn: 'Login',
            altLoginBtn: 'Login',
            apiMsg: {
                'default': '登入失敗，帳號或密碼錯誤',
                '-4': '您前次使用的是 Google 帳號登入方式'
            }
        },
        signup: {
            title: 'Sign up',
            altTitle: 'or sign up with your social media account',
            google: 'Sign up with Google',
            input: {
                name: 'Name',
                email: 'This Email is your login account',
                password: 'Password',
                passwordConfirm: 'Confirm the password',
                passwordConfirmError: 'The password you entered is inconsistent'
            },
            rulesLink: 'Continue to indicate your consent',
            extraLink: 'Has been registered',
            signupBtn: 'Register'
        },
        password: {
            title: 'Password',
            forgot: 'Forgot password?',
            hint: 'Please input the Email for your registration',
            resetBtn: 'Reset password'
        },
        oAuth: {
            title: 'Protection of Personal Data',
            desc: 'The use of third-party community login authentication mechanism (OAuth2.0 RFC6749) can protect your personal information and system data security better for we have no access or store any of your password and neither do we have permission to post or access any images in your community account. If you are an employee of a corporate user, the login will be used only to bind the management account. Your community name and profile will be visible only to you, so you can login the system with worries.'
        },
        match: {
            content:
            `<h2>無可用的管理權限</h2>
            <hr />
            <p>管理權限是您在雲端平台上的操作權限，不同權限看到的資料及功能有些區別</p>
            <ul>
                <li>公司管理：可看到公司旗下<strong>所有站點</strong>的資料</li>
                <li>站點管理：可看到<strong>指定站點</strong>的資料</li>
            </ul>
            <p>請聯繫您的經銷商或管理者，為您的雲端平台帳號增加新的管理權限。</p>`,
            logout: '重新登入'
        },
        control: {
            changePassword: 'Change password',
            logout: 'Logout',
            match: 'Get more credential',
            switch: 'Switch credential'
        },
        roles: {
            // 權限的排列順序
            order: ['1', '2', '3', '4', '5', '0'],

            // permission 為可新增的管理權限
            // tm 及 regular 目前系統未使用
            '1': {
                code: 'cem',
                title: '銓勝管理',
                permission: [1,2,3,4]
            },
            '2': {
                code: 'dm',
                title: '經銷管理',
                permission: [3,4]
            },
            '3': {
                code: 'com',
                title: '公司管理',
                permission: [3,4]
            },
            '4': {
                code: 'sm',
                title: '站點管理',
                permission: [4]
            },
            '5': {
                code: 'tm',
                title: '酒測管理',
                permission: []
            },
            '0': {
                code: 'regular',
                title: '一般會員',
                permission: []
            }
        },
        privacy: 'Privacy Policy',
        terms: 'Terms of sevices'
    },

    /* ----- 各種操作回饋訊息 ----- */
    feedbackMsg: {
        // 空白內容提示
        emptyHint: {
            default: {
                title: 'No company or site selected',
                desc: 'You can sort specific items'
            },
            default2: {
                title: 'No company selected',
                desc: 'Please select a company or all sites first'
            },
            company: {
                title: 'No company data',
                desc: 'No data found'
            },
            site: {
                title: 'No site data',
                desc: 'No sites of this company found'
            },
            noResult: {
                title: 'No contents found',
                desc: 'Please add or try later'
            },
            dataError: {
                title: 'Wrong information format',
                desc: 'Please try again later'
            }
        },

        // 尚未選擇或缺少的項目
        lack: {
            company: 'A company must be selected',
            site: 'A site must be selected',
            keyword: 'No key word is input'
        },

        // 搜尋相關
        search: {
            notFound: 'No matched results found',
            notFound2: 'No match items'
        },

        // API 相關
        api: {
            sending: 'Data transmitting',
            querying: 'Searching...',
            error1: {
                title: 'Data loading error, error code: {variable}',
                hint: 'Please click on the confirmation to refresh the page'
            },
            error2: {
                title: 'Data error, please check if the data format is correct or repeated',
                hint: ''
            },
            error3: {
                title: 'An error occurred, please try again later',
                hint: ''
            },
            // error4: {
            //     title: 'PULL Service APIs disabled',
            //     hint: ''
            // }
        },

        // 上傳檔案相關
        upload: {
            image: {
                success: 'Image uploaded successfully',
                fail: 'Image failed to upload',
            }
        }

    },

    // ----- component: dialogBox ----- //
    dialogBox: {
        btn: {
            confirm: 'Confirm',
            cancel: 'Cancel',
            close: 'Close'
        }
    },

    // ----- component: messageBox ----- //
    messageBox: {
        btn: {
            close: 'Close'
        }
    },

    // ----- component: SortingTable ----- //
    sortingTable: {
        btn: {
            edit: '編輯',
            delete: '刪除'
        },

        error: {
            noCompany: {
                msg1: '請選擇公司與站點',
            },
            noSite: {
                msg1: '此公司尚無站點',
                msg2: '請選擇站點',
            },
            noData: {
                msg1: '目前尚無內容',
            }
        }

    },

    // ----- component: selectBox (公司站點選單) ----- //
    selectBox: {
        coms: {
            label: '請選擇公司',
            hint: '搜尋公司名稱、統編或 ID'
        },
        sites: {
            label: '請選擇站點',
            hint: '搜尋站點名稱或 ID'
        },
        empty: {
            company: '目前尚無公司',
            site: '目前尚無站點'
        },
        all: {
            company: '全部公司',
            site: '全部站點'
        },
        notFound: '沒有符合的項目' // 用關鍵字篩選時沒有符合的項目
    },

    // ----- component: sideInfoBox ----- //
    sideInfoBox: {
        hint: {
            noData: '無相關資料紀錄',
            close: '可按內容區以外任意空白處關閉'
        }
    },

    /* ----- 元件：slidebox ----- */
    slidebox: {
        btn: {
            confirm: 'Confirm',
            cancel: 'Cancel'
        },
        select: {
            city: {
                label: 'County or City',
                option: 'Select the County or City'
            },
            district: {
                label: 'District',
                option: 'Select District'
            }
        },

        error1: {
            title: 'No updates',
            hint: ''
        }
    },

    // ----- component: 批次停用 ----- //
    deviceDebatch: {
        trigger: '批次停用',
        btn: {
            cancel: 'Cancel'
        },
        box: {
            title: '批次停用酒測器',
            hint: '將不同序號以空白隔開，即可批次停用多筆酒測器',
            error: '請輸入序號',
            success: '已停用 {variable} 支酒測器'
        }
    },

    /* ----- 元件：Tagger ----- */
    tagger: {
        default: {
            hint: 'Add a label: Enter the name and press Enter or the plus button to add a new label.',
            placeholder: 'Add label',
            emptyHint: 'No label set'
        },

        // 公司進階設定：Operation types
        operationType: {
            hint: 'Add type: Enter the name and press Enter or the plus button to add a new label.',
            placeholder: 'Add type',
            emptyHint: 'No Operation type set'
        }
    },

    /* ----- 元件：重要系統公告 ----- */
    specialAnnouncement: {
        display: false,
        title: 'Announcement',
        content: [
            '相容性問題：目前已知 Android 14 有機率造成酒測助手 APP 拍照時出現黑色區塊，導致照片部分內容被遮蔽，請安卓手機平板用戶<strong>暫緩升級至 Android 14</strong>，造成不便尚請見諒，待問題排除後將另行發佈公告。'
        ],
        close: 'Close'
    },

    /* ----- 月份名稱 ----- */
    monthNames: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec',
    ],

    dayNameOfWeek: {
        Sunday: 'Sunday',
        Monday: 'Monday',
        Tuesday: 'Tuesday',
        Wednesday: 'Wednesday',
        Thursday: 'Thursday',
        Friday: 'Friday',
        Saturday: 'Saturday',
    },

    /* ----- 元件：裝置月報表 ----- */
    deviceUsageReport: {
        checkin_time: 'Check-in 時間',
        company_name: '公司',
        // device_count_total: '累計量測次數',
        device_status: '狀態',
        dist_name: '經銷商',
        earliest_time: '最初量測時間',
        latest_time: '最後量測時間',
        last_updated: '最後更新時間',
        license_key: '授權碼',
        model_name: '型號',
        serial_no: '序號',
        test_count: '硬體使用次數',
        transfer_log: '轉移紀錄',

        // summary 中使用的
        group: '類別',
        startDate: '報表起算日',
        endDate: '報表結束日',

        enabled: '待命', // 0
        billed: '使用中', // 1
        spare: '備品', // 2
        sample: '樣品', // 3
        disabled: '已停用', // -1
        deleted: '已刪除', // -2

        // for export，用來產生 sheetHeader 中的欄位順序
        // 注意這邊只用來指定順序，欄位名稱必須要對應上面 deviceUsageReport 中列出的欄位名稱
        thReference: {
            summary: [
                '類別',
                '使用中', '待命', '已停用', '備品', '樣品', '已刪除',
                '報表起算日', '報表結束日'
            ],
            record: [
                '經銷商', '公司', '型號', '序號', '授權碼', '狀態', '量測次數',
                '最初量測時間', '最後量測時間', 'Check-in 時間', '最後更新時間'
            ]
        },

        // 轉移紀錄
        transferLog: {
            active: '狀態',
            company_sname: '公司',
            initial_test_count: '轉入時使用次數',
            site_sname: '站點',
            timestamp: '轉入時間',
        },

        ui: {
            trigger: '裝置月報表'
        },

        // message
        message: {
            noData: '無報表資料',
        }
    }, // end of 裝置月報表

    /* ----- 元件：授權碼報表 ----- */
    licenseUsageReport: {

        // 資料欄位
        'company': '公司名稱',
        'company_type': '公司類型',
        'enable_hr_bp': '血壓',
        'enable_punch_in': '打卡',
        'license_creation_time': '授權碼建立時間',
        'latest_measurement_time': '最近量測時間',
        'license_key': '授權碼',
        'license_update_time': '授權碼異動時間',

        'mode': '授權模式',
        'report_time': '報表結算日',
        'serial_no': '裝置序號',
        'site': '站點',

        'tester_employee_number': '工號',
        'tester_name': '受測者',

        // Summary 開通服務數量
        '_service': {
            'single': '使用服務數量 / 單人',
            'multi': '使用服務數量 / 多人',
            'alcohol': '酒測',
            'hrbp': '血壓',
            'punchin': '打卡',
        },

        '_clientSum': '<strong>經銷客戶端</strong>數量總計 (不含經銷商)</small>',
        '_deviceType': '裝置類型',
        '_idleTime': '裝置閒置天數',
        '_edgeCount': 'APP 用戶端數量',

        '_usageCount_alc': '使用次數/酒測',
        'usage_count_hrbp': '使用次數/血壓',
        'usage_count_punchin': '使用次數/打卡',

        ui: {
            title: '授權碼報表',
            button: {
                close: 'Close',
                export: '匯出 Excel',
                trigger: '授權碼報表', // header 開啟授權碼報表功能的按鈕
                query: '開始查詢'
            }
        },

        // 說明
        instruction: [
            '報表結算日：{reportTime}，請使用「匯出 Excel」功能查閱授權碼明細'
        ],

        exportNote: [
            '「報表結算日」為每月系統結算與儲存授權碼資料的時間，本報表內容均以此基準進行統計',
            '授權碼詳細使用紀錄請看「License list」工作表',
            'Summary：各公司使用中的服務數量',
            'Multimode usage：僅多人模式提供此分頁，多人模式下各站點的使用 (量測) 次數統計',
            'License list：授權碼明細',
            'Idle：30 天以上無使用紀錄的授權碼不列入統計，列在此分頁中作為備查',
            '',
            '欄位說明如下',
            '站點：授權碼綁定的站點，若此欄是空值代表「個人版自動授權」或「未綁定過的授權碼」',
            '',
            '以下欄位的值來自與授權碼關聯的「最近一次量測紀錄」',
            '授權模式：若此欄空值代表「查無此授權碼相關的量測紀錄」或「用戶端 APP 版本過舊」',
            '裝置序號：若此欄空值代表「查無此授權碼相關的量測紀錄」(無綁定的裝置或綁定後從未進行量測)',
            '裝置類型：根據「裝置序號」判斷是無線或有線酒測器，若此欄空值代表裝置非酒測器 (例如血壓計) 或無綁定的裝置或綁定後從未進行量測',
            '裝置閒置天數：從「最近量測時間」到「報表結算日」的天數，若此欄空值代表「查無此授權碼相關的量測紀錄」'
        ],

        // export (授權碼清單 only，統計頁的部分使用 table_to_sheet 產生)
        // 用來指定 sheetHeader 中的欄位順序
        // 會同時影響在表格中要顯示哪些欄位
        // 注意以下欄位名稱必須是 key 值轉換後的中文欄位名稱
        sheetHeader: {
            'License list': [
                '公司類型', '公司名稱', '站點', '授權模式',
                '授權碼', '授權碼建立時間', '授權碼異動時間',
                '使用次數/酒測', '使用次數/血壓', '使用次數/打卡',
                '裝置序號', '裝置類型', '最近量測時間', '裝置閒置天數',
                '受測者', '工號', '報表結算日'
            ],
            // 閒置未使用，欄位與 License list 相同
            'Idle': [
                '公司類型', '公司名稱', '站點', '授權模式',
                '授權碼', '授權碼建立時間', '授權碼異動時間',
                '使用次數/酒測', '使用次數/血壓', '使用次數/打卡',
                '裝置序號', '裝置類型', '最近量測時間', '裝置閒置天數',
                '受測者', '工號', '報表結算日'
            ],
            'Multimode usage': [
                '公司類型', '公司名稱', '站點', 'APP 用戶端數量',
                '使用次數/酒測', '使用次數/血壓', '使用次數/打卡',
                '報表結算日'
            ]
        }

    }, // end of 授權碼報表

    // ----- Dashboard ----- //
    dashboard: {
        tips: {
            initial: '請選擇公司與站點',
            noCompany: '請選擇公司',
            noSite: '請選擇站點',
            noData: '指定的資料不存在',
            syncData: '將於 {variable} 秒後自動更新，<br />或按此按鈕立即更新資料',
            scheduleDataFailed: '班表資料載入失敗',
            scheduleTimeDelay: '晚 {variable} 分', // 班表中，實際量測時間比預計量測時間晚
        },
        loading: {
            cancel: '取消載入'
        },
        setting: {
            'today': '今日',
            'hr12': '12 <small>小時內</small>',
            'hr24': '24 <small>小時內</small>',
            'hr48': '48 <small>小時內</small>'
        },
        tag: {
            alcohol: '酒測',
            bloodPressure: '血壓',
            crossSite: '外站',
            departure: '發車',
            noSchedule: '本日無班表',
            punchin: '打卡',
        },
        panel: {
            title: {
                all: '所有紀錄',
                failed: '未通過清單',
                testers: '本站編制',
                schedules: '今日班表'
            },
            // crossSite: '外站',
            employeeNumber: '工號：{variable}', // 受測者工號
            sum: '{variable} 筆', // 資料筆數

            tested: '有紀錄',
            untested: '無紀錄',
            others: '其他',

            warningValue: '橘色警示',
            errorValue: '紅色警示',
        },
        // 可以看到「全部站點選項」的公司 id
        allSiteEnabled: {
            'TW': [12, 278], // 12 美食家, 278 銓勝 demo
            'JP': [],
        }
    },

    // ----- 檢核表 ----- //
    inspection: {
        sum: '{variable} 筆紀錄',
        sorting: {
            'label': '排序',
            'desc': '新的在前',
            'asc': '舊的在前',
            'formid': '類別'
        },
        header: {
            'created': '建立日期',
            'download': '下載',
            'manager': '管理者',
            'siteName': '公司 / 站點',
            'title': '類別',
            'view': '預覽'
        },
        TW001: {
            title: '台灣運輸管理自主檢查表'
        },
        JP001: {
            title: '酒気帯び確認記録',
        },

    },

    // ----- 使用次數說明 ----- //
    testCountTooltip: {
        trigger: '使用次數說明',
        content:
        `<h2>硬體使用次數</h2>
        <p>這是酒測器韌體所紀錄的硬體使用次數，作為<strong>硬體是否需要校正</strong>的依據</p>
        <ul>
            <li>酒測器返回原廠校正後此數字會歸零</li>
            <li>酒測器的轉移操作不影響硬體使用次數</li>
            <li>APP 上顯示的為此硬體使用次數</li>
            <li>APP 離線使用時無法上傳資料至雲端伺服器，因此在雲端上看到的有可能跟 APP 上顯示的不一致</li>
        </ul>
        <hr />
        <h2>雲端使用次數</h2>
        <p>這是雲端伺服器根據量測紀錄所統計的使用次數，作為<strong>按使用次數收費</strong>的依據</p>
        <ul>
            <li>當酒測器被轉移時，系統會記錄轉移前的使用次數，您在雲端看到的數字已自動扣除轉移前的次數</li>
            <li>APP 離線使用時無法上傳資料至雲端伺服器，因此將無法統計離線狀態下的使用次數</li>
        </ul>`,
    },

    // ----- 管理權限說明 ----- //
    managersTooltip: {
        trigger: '管理權限說明',
        content:
        `<h2>管理權限說明</h2>
        <p>管理權限是您在雲端平台上的操作權限，不同權限看到的資料及功能有些區別</p>
        <ul>
            <li>公司管理：可看到公司旗下<strong>所有站點</strong>的資料</li>
            <li>站點管理：可看到<strong>指定站點</strong>的資料</li>
            <li>您可以隨時為自己或他人建立新權限</li>
            <li>一個人可擁有多種不同權限，請開啟畫面右上角的使用者選單切換權限</li>
        </ul>
        <hr />
        <h2>權限綁定</h2>
        <ul>
            <li>管理權限建立後會自動與<strong>對應的雲端平台帳號</strong>綁定</li>
            <li>如果帳號綁定顯示「未綁定」，表示雲端平台上還沒有該帳號 Email 的使用者，請通知指定的對象註冊並登入雲端平台，就可自動完成綁定</li>
        </ul>`,
    },

    // ----- 變更密碼 ----- //
    changePwBox: {
        title: '變更密碼',
        label: {
            current: '請輸入舊密碼',
            new: '輸入新密碼',
            confirm: '再次輸入新密碼'
        },
        msg: {
            failed: '變更失敗，請確認舊密碼是否正確',
            incorrect: '舊密碼錯誤',
            mismatch: '請輸入相同的新密碼',
        },
        success: {
            title: '密碼設定完成',
            msg: '下次登入請使用新密碼',
            close: '關閉'
        }
    },

}

module.exports = text;