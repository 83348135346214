// SheetJS, https://github.com/sheetjs/sheetjs
// Reference: 使用js-xlsx純前端匯出excel
// https://www.itread01.com/content/1542881413.html

// const XLSX = require('xlsx');

// helper
function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

// helper: 建立下載檔案
function openDownloadDialog(url, saveName) {

    if (typeof url == 'object' && url instanceof Blob) {
        url = URL.createObjectURL(url); // 建立blob地址
    }

    const aLink = document.createElement('a');

    aLink.href = url;
    
    // HTML5新增的屬性，指定儲存檔名，可以不要字尾，注意，file:///模式下不會生效
    aLink.download = saveName || '';
    
    let event;
    
    if (window.MouseEvent){
        event = new MouseEvent('click');
    } else {
        event = document.createEvent('MouseEvents');
        event.initMouseEvent(
            'click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null
        );
    }

    // 移除載入中提示
    document.body.classList.remove('loading');

    aLink.dispatchEvent(event);
}


/* ----- 匯出資料為 xlsx ----- */
/*
需要準備：
1. sheetName (Array): 在 xlsx 中要產生的 sheet 名稱
2. sheetHeader (Object): 欄位名稱與顯示順序
3. sheetData (Object): 要匯出的資料
4. fileName: 要儲存的檔名

傳入的資料格式範例

// sheets 名稱
const sheetName = [
    '摘要', '清單'
]

// 指定欄位顯示的順序
// 注意這邊只用來指定順序，欄位名稱必須要對應中文欄位名稱
const sheetHeader = {
    '摘要': ['公司名稱', '公司類型', '授權模式'...],
    '清單': ['站點', '授權模式', '授權碼'... ]
}

// sheetData 報表資料，需整理成以下範例格式
// sheet 的 key 值對應 sheetName
// 個別資料物件中的 key 值對應 sheetHeader 中的欄位名稱
const sheetData = {
    '摘要': [{
        公司名稱: 'xxx', 
        公司類型: 'xxx', 
        授權模式: 'xxx',
        ...
    }, ...],
    '清單': [{
        站點: 'xxx', 
        授權模式: 'xxx',
        授權碼: 'xxx',
        裝置序號: 'xxx',
        ...
    }, ...]
}

width 欄位寬度設定，預設為 30
otherSheet: 預設用的是 json_to_sheet 方法，從這邊傳入其他方法產生的 sheet (例如 table_to_sheet)
otherSheet = {
    name: 'Summary',
    content: XLSX.utils.table_to_sheet(
        document.querySelector('#licenseUsageReportBox table')
    )
}
*/
export function exportXlsx(sheetName, sheetData, sheetHeader, fileName, width = 30, otherSheet = null){
    
    const workbook = {
        SheetNames: sheetName,
        Sheets: {}
    };

    sheetName.forEach((item, index) => {

        if(!sheetHeader[item]){
            return;
        }
        
        const sheet = XLSX.utils.json_to_sheet(
            sheetData[item], {
                header: sheetHeader[item]
            }
        );

        // 可根據 sheetHeader 得知欄位數量
        const colsAmount = sheetHeader[item].length;

        let colsWidth = [];

        for(let i = 1; i <= colsAmount; i++){
            colsWidth.push({wch: width});
        }

        sheet["!cols"] = colsWidth;

        /*
        // 欄位寬度設定範例
        sheet["!cols"] = [
            { wch: 30 },
            { wch: 30 },
            { wch: 30 },
            { wch: 30 },
            { wch: 30 },
            { wch: 30 },
            { wch: 30 },
            { wch: 30 },
        ];
        */

        workbook.Sheets[item] = sheet;
    });

    if(otherSheet){
        workbook.Sheets[otherSheet.name] = otherSheet.content;
    }

    const wopts = {
        bookType: 'xlsx',
        bookSST: false,
        type: 'binary'
    };

    const wbout = XLSX.write(workbook, wopts);
    
    const blob = new Blob([s2ab(wbout)], {
        type: "application/octet-stream"
    });

    openDownloadDialog(blob, `${fileName}.xlsx`);

}