import './changePw.less';

import {
    sendApiRequest,
    // globalErrorHandler,
    // getManagersMenu
} from '../../js/api';

import {dialogBox} from '../dialogBox/dialogBox';

// import {showFeedbackMsg} from '../../js/helper/feedbackMsg';

export class changePassword {

    constructor(instanceName){

        // 呼叫時用來儲存實體的 window 變數名稱 (window.changePwBox)
        // 在關閉後要清空此變數
        this.instanceName = instanceName;

        this.boxId = 'changePwBox';
        this.box = new dialogBox(this.boxId, false, false);

        this.container = this.box.container;

        this.inputData = {};

        // input 時檢查輸入的新密碼是否一致
        this.mismatch = true;

        this.uiTxt = window.langTxt.changePwBox;

        this.init();
    }

    init(){

        this.setContent();

        this.events();

        setTimeout(() => {
            this.box.open();
        }, 0);

        // this.box.open();

    }

    setContent(){
        try {
            // 設定標題
            const uiTxt = this.uiTxt;
            this.box.set_title(uiTxt.title);

            function toggle(targetId){
                const Html =
                `<div class="visibility-toggle" data-for="${targetId}">
                    <svg class="show">
                        <use xlink:href="/assets/sprites/sprites_solid.svg#eye"></use>
                    </svg>
                    <svg class="hide">
                        <use xlink:href="/assets/sprites/sprites_solid.svg#eye-slash"></use>
                    </svg>
                </div>`;
                return Html;
            }

            const content =
            `<div class="inputwpr">
                <label>${uiTxt.label.current}</label>
                <div class="inputbox">
                    <input id="CPcurrent" type="password" required autocomplete="new-password" name="current_password" />
                    ${toggle('CPcurrent')}
                </div>
            </div>
            <div class="inputwpr">
                <label>${uiTxt.label.new}</label>
                <div class="inputbox">
                    <input id="CPnew" type="password" required autocomplete="new-password" name="password" />
                    ${toggle('CPnew')}
                </div>
            </div>
            <div class="inputwpr">
                <label>${uiTxt.label.confirm}</label>
                <div class="inputbox">
                    <input id="CPconfirm" type="password" required autocomplete="new-password" name="password_confirmation" />
                    ${toggle('CPconfirm')}
                </div>
            </div>
            <div id="CPErrMsg"></div>
            <div id="CPsuccess"></div>`;

            this.box.set_content(content);

            // TEST
            // this.showSuccessMessage();

        } catch(error){
            console.log(error);
            this.destroy();
        }
    }

    // ----- 關閉後清除對話框 ----- //
    destroy(){

        this.box.close();

        setTimeout(() => {
            // 關閉之後清空建立的 instance
            window[this.instanceName] = null;

            // 清除 DOM
            try {
                this.container.parentNode.removeChild(this.container);
            } catch(error){
                console.log(error);
            }
        },500);
    }

    // ----- 切換密碼顯示 ----- //
    toggleVisibility(thisDom, inputId){
        try {
            const input = this.container.querySelector(`#${inputId}`);

            if(thisDom.classList.contains('visible')){
                // input切換成 password
                input.type = 'password';
                thisDom.classList.remove('visible');
            } else {
                // input 切換成 text
                input.type = 'text';
                thisDom.classList.add('visible');
            }

        } catch(error){
            console.log(error);
        }
    }

    // ----- 顯示錯誤訊息 ----- //
    // 傳入 false 可以清空訊息
    showErrMessage(text){

        const CPErrMsg = this.container.querySelector('#CPErrMsg');

        if(text === false){
            CPErrMsg.innerHTML = '';
        } else {
            CPErrMsg.innerHTML = `<p>${text}</p>`;
        }
    }

    // ----- 顯示完成對話框 ----- //
    showSuccessMessage(){

        const wrapper = this.container.querySelector('#CPsuccess');

        const text = this.uiTxt.success;

        const Html =
        `<div class="innerwpr">
            <h3>${text.title}</h3>
            <p>${text.msg}</p>
            <div class="icon">
                <svg><use xlink:="" href="/assets/sprites/sprites_solid.svg#check"></use></svg>
            </div>

            <div class="btn confirm" id="${this.boxId}complete">${text.close}</div>
        </div>`;

        wrapper.innerHTML = Html;

        setTimeout(() => {
            this.container.classList.add('success');
        }, 100);
    }

    // ----- 送出請求 ----- //
    async sendRequest(){

        // 必須有輸入舊密碼
        if(!this.inputData['current_password']){
            this.showErrMessage(this.uiTxt.label.current);
            return;
        } else {
            this.showErrMessage(false);
        }
        // 輸入的新密碼必須一致
        if(this.mismatch){
            this.showErrMessage(this.uiTxt.msg.mismatch);
            return;
        }

        try {
            const payload = {...this.inputData};
            delete payload['password_confirmation'];

            document.body.classList.add('loading');

            let apiData = null;
            apiData = await sendApiRequest('put', '/password', payload)
                .then(res => res.data);

            document.body.classList.remove('loading');

            // 顯示成功訊息對話框
            this.showSuccessMessage();

        } catch(error){
            document.body.classList.remove('loading');
            // 變更密碼失敗，請確認舊密碼是否正確
            this.showErrMessage(this.uiTxt.msg.failed);
        }

    }

    // ----- 事件處理 ----- //
    events(){

        this.container.addEventListener('click', (event) => {

            // 關閉後清除對話框
            if(event.target.id === `${this.boxId}cancel` ||
               event.target.id === `${this.boxId}complete`){
                this.destroy();
            }

            if(event.target.id === `${this.boxId}confirm`){
                this.sendRequest();
            }

            // 切換密碼顯示
            if(event.target.classList.contains('visibility-toggle')){
                this.toggleVisibility(
                    event.target,
                    event.target.dataset.for
                );
            }
        });

        this.container.addEventListener('input', (event) => {

            if(event.target.tagName === 'INPUT'){

                const input = event.target;
                const value = input.value;

                if(value.length > 0){
                    this.inputData[input.name] = value;
                } else {
                    delete this.inputData[input.name];
                }

                // 「輸入新密碼」與「再次輸入新密碼」
                // 執行相同的檢查程序
                if(input.id === 'CPnew' || input.id === 'CPconfirm'){
                    try {
                        let password = '';
                        let passwordConfirmation = '';

                        if(input.id === 'CPnew'){
                            password = value;
                            passwordConfirmation = this.container.querySelector('#CPconfirm').value;
                        }

                        if(input.id === 'CPconfirm'){
                            password = this.container.querySelector('#CPnew').value;
                            passwordConfirmation = value;
                        }

                        if(password !== passwordConfirmation){
                            // 輸入的新密碼不一致
                            this.showErrMessage(this.uiTxt.msg.mismatch);
                            this.mismatch = true;
                        } else {
                            // 密碼一致，通過檢查
                            this.showErrMessage(false);
                            this.mismatch = false;
                        }
                    } catch(error){
                        console.log(error);
                    }
                }
            }

        });

    }

}