import './deviceDebatch.less';

import {dialogBox} from '../dialogBox/dialogBox';

import {sendApiRequest, globalErrorHandler} from '../../js/api';

import {showFeedbackMsg} from '../../js/helper/feedbackMsg';

/* ----- 批次停用酒測器 ----- */
export class DeviceDeBatch {

    constructor(roleID, container){

        this.roleID = roleID;       
        this.container = container;

        this.trigger = null;
        this.deBatchBox = new dialogBox('deviceDebatchBox', true, false);   
        
        if(!this.container){
            return;
        }

        this.boxTxt = window.langTxt.deviceDebatch;

        this.init();
    }

    init(){

        this.trigger = document.createElement('div');
        this.trigger.id = 'deviceDebatchTrigger';
        this.trigger.className = 'btn-control';
        this.trigger.innerHTML = 
        `<svg>
            <use xlink:href="/assets/sprites/sprites_solid.svg#minus-circle"></use>
        </svg>${this.boxTxt.trigger}`;

        this.container.appendChild(this.trigger);

        // 設定對話框標題
        const title = 
        `<svg class="title-icon"><use xlink:href="/assets/sprites/sprites_solid.svg#minus-circle"></use></svg>${this.boxTxt.box.title}`;
        this.deBatchBox.set_title(title);

        // 設定對話框提示文字   
        this.deBatchBox.set_hint(
            this.boxTxt.box.hint
        );

        // 設定 cancel 按鈕文字 (將取消改成關閉)
        this.deBatchBox.set_cancelBtnTxt(
            this.boxTxt.btn.cancel
        )

        this.events();
    }

    events(){

        // 開啟對話框
        this.trigger.addEventListener('click', () => {
            this.openDebatchBox();
        });

        const dialogBox = document.querySelector('#deviceDebatchBox');
        dialogBox.addEventListener('click',(event) => {

            const target = event.target;

            // 取消並關閉對話框
            if(target.id === 'deviceDebatchBoxcancel'){
                this.deBatchBox.close(true);
            }

            // 確認
            if(target.id === 'deviceDebatchBoxconfirm'){
                this.confirmDebatch();
            }

        });

    }

    /* ----- 開啟對話框 ----- */
    openDebatchBox(){        

        const content = 
        `<textarea></textarea>
        <div class="feedBack"></div>
        `;

        // 建立序號輸入框
        this.deBatchBox.set_content(content);

        this.deBatchBox.open();
    }

    /* ----- 確認批次停用酒測器 ----- */
    async confirmDebatch(){

        const dialogBox = document.querySelector('#deviceDebatchBox');

        const textInput = dialogBox.querySelector('textarea');
        const inputValue = textInput.value;  

        if(inputValue.length < 1){
            showFeedbackMsg(this.boxTxt.box.error, 'fail');
            return;
        }

        // 去除前後空白字元
        const _text = inputValue.trim();

        // 將換行字元替換為空白字元
        let text = _text.replace(/(\r)?\n/g,' ');     

        // 將字串間的空白字元，替換為 /
        text = text.replace(/ +/g,'/');           

        // 將結果轉換為 Array
        const output = text.split('/'); 

        // 要送出的參數
        const fetchData = {
            manager_id: this.roleID,       
            serial_no_batch: JSON.stringify(output)       
        }    

        let result = null;
        try {
            result = await sendApiRequest('post', '/devices/debatch', fetchData)
                .then(res => res.data);

        } catch(error){
            globalErrorHandler(error);
            // ...其他錯誤處理程序
        } 
       
        // API 返回此次操作所停用的酒測器
        const devices = result.data.devices;

        let list = '';

        if(devices.length > 0){

            list += '<ul>';

            devices.forEach((item) => {
                list += `<li>${item.serial_no}</li>`;
            });

            list += '</ul>';
        }     

        const message = this.boxTxt.box.success.replace('{variable}', devices.length);
    
        showFeedbackMsg(`${message}${list}`, 'success', 5000);

        textInput.value = '';

    }


}