export const tableMap = [
    {
        key: 'dist_name',
        sorting: true,
        visibility: true
    },
    {
        key: 'company_name',
        sorting: true,
        visibility: true
    },
    {
        key: 'model_name',
        sorting: true,
        visibility: true
    },
    {
        key: 'serial_no',
        sorting: true,
        visibility: true
    },
    {
        key: 'license_key',
        sorting: true,
        visibility: true
    },
    {
        key: 'device_status',
        sorting: true,
        visibility: true
    },
    {
        key: 'test_count',
        sorting: true,
        visibility: true
    },
    // {
    //   key: 'device_count_total',
    //   sorting: true,
    //   visibility: true
    // },
    {
        key: 'earliest_time',
        sorting: true,
        visibility: true
    },
    {
        key: 'latest_time',
        sorting: true,
        visibility: true
    },
    {
        key: 'checkin_time',
        sorting: true,
        visibility: true
    },
    {
        key: 'last_updated',
        sorting: true,
        visibility: true
    },
    {
        // 查詢裝置轉移紀錄
        key: 'transfer_log',
        sorting: false,
        visibility: true,
        permission: ['cem']
    }

];