// ----- table header ----- //
export function countsTHtemplate(langTxt){

    const html =
    `<thead>
        <tr class="lv1">
            <th rowspan="2">${langTxt['company_type']}</th>
            <th rowspan="2">${langTxt['company']}</th>
            <th colspan="3">${langTxt['_service']['single']}</th>
            <th colspan="3">${langTxt['_service']['multi']}</th>
            <th rowspan="2" class="hidden">${langTxt['report_time']}</th>
        </tr>
        <tr class="lv2">
            <th>${langTxt['_service']['alcohol']}</th>
            <th>${langTxt['_service']['hrbp']}</th>
            <th>${langTxt['_service']['punchin']}</th>
            <th>${langTxt['_service']['alcohol']}</th>
            <th>${langTxt['_service']['hrbp']}</th>
            <th>${langTxt['_service']['punchin']}</th>
        </tr>
    </thead>`;

    return html;
}

/* ----- 欄位資料轉換 ----- */
function parseData(dataItem, key, comTypesTxt = null){
    // dataItem: 資料
    // key: 欄位名稱
    // comTypesTxt: 公司類型的文字對照表，來自文字檔中的 pageComs.types

    const value = dataItem[key];
    let output = '';

    switch(key){

        // 公司類型
        case 'company_type':
            if(comTypesTxt[value]){
                output = comTypesTxt[value];
            }
            break;

        case 'report_time':
            output = (value)? value : '';
            break;

        default:
            output = value;
    }

    switch(output){
        // 用較淺的顏色顯示
        case 0:
        case '0':
        case 'No':
            output = `<span class="fade">${output}</span>`;
            break;
    }

    return output;
}

// ----- table content ----- //
export function countsTableRow(Data, comTypesTxt){

    const comType = Data['company_type'];

    const html =
    `<tr class="t-${comType}">
        <td>${parseData(Data, 'company_type', comTypesTxt)}</td>
        <td>${Data['company']}</td>
        <td>${parseData(Data['service']['single'], 'alcohol')}</td>
        <td>${parseData(Data['service']['single'], 'hrbp')}</td>
        <td>${parseData(Data['service']['single'], 'punchin')}</td>
        <td>${parseData(Data['service']['multi'], 'alcohol')}</td>
        <td>${parseData(Data['service']['multi'], 'hrbp')}</td>
        <td>${parseData(Data['service']['multi'], 'punchin')}</td>
        <td class="hidden">${parseData(Data, 'report_time')}</td>
    </tr>`;

    return html;
}

// ----- 計算經銷客戶的數量總計 ----- //
export function sumTableRow(Data, langTxt){

    // 經銷客戶的數量
    let comTotal = 0;

    // 參照 slot 中的格式
    const sum = {
        // 授權模式：單人
        'single': {
            'alcohol': 0, // 酒測
            'hrbp': 0, // 血壓
            'punchin': 0 // 打卡
        },
        // 授權模式：多人
        'multi': {
            'alcohol': 0,
            'hrbp': 0,
            'punchin': 0
        }
    }

    // 只需要 company_type === '4' 的資料 (經銷客戶)
    Data.forEach((item) => {

        if(parseInt(item['company_type'], 10) === 4){

            comTotal += 1; // 經銷客戶數量

            // 將數量加總並記錄在 sum 中
            // prop1: single / multi
            for(const prop1 in item.service){
                // prop2: alcohol / hrbp / punchin
                for(const prop2 in item.service[prop1]){
                    sum[prop1][prop2] += item.service[prop1][prop2];
                }
            }
        }
    });

    // 如果沒有經銷客戶就不用顯示數量總計列
    if(comTotal < 1){
        return '';
    }

    // 先插入一行隱藏的空白列作為分隔
    const colspan = 'colspan="9"';
    const emptyRow =
    `<tr>
        <td class="hidden" ${colspan}>&nbsp;&nbsp;</td>
    </tr>`;


    const reportTime =
    (Data[0]['report_time'])? Data[0]['report_time'] : '';

    const html =
    `${emptyRow}
    <tr class="client-sum">
        <td colspan="2">${langTxt['_clientSum']}</td>
        <td>${sum['single']['alcohol']}</td>
        <td>${sum['single']['hrbp']}</td>
        <td>${sum['single']['punchin']}</td>
        <td>${sum['multi']['alcohol']}</td>
        <td>${sum['multi']['hrbp']}</td>
        <td>${sum['multi']['punchin']}</td>
        <td class="hidden">${reportTime}</td>
    </tr>`;

    return html;
}

// ----- 附加在表格底部的說明列 ----- //
export function exportNoteTableRow(Data){

    let content = '';

    const colspan = 'colspan="9"';

    // 先插入兩行空白列作為分隔
    // 1. display:none 必須設定在 td
    // 2. td 中必須要有空白字元
    // 否則 table_to_sheet 輸出時會忽略這些內容
    const emptyRow =
    `<tr>
        <td class="hidden" ${colspan}>&nbsp;&nbsp;</td>
    </tr>`;

    content += emptyRow;
    content += emptyRow;

    Data.forEach((item) => {
        content +=
        `<tr>
            <td class="hidden" ${colspan}>${item}</td>
        </tr>`;
    });

    const html = content;

    return html;
}