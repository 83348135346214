// ----- 返回日期 YYYY-MM-DD ----- //
export function getDate(day = null, dateObj = null){
    /*
    day: 'today' | 'yesterday'
    指定是今日或昨日，不指定的話預設為今日
    昨日的計算方式: https://stackoverflow.com/a/32301169

    如果有傳入 dateObj (Date Object) 的話，以處理 dateObj 為優先
    */

    let date = null;

    if(dateObj){
        date = dateObj;
    } else {

        if(day == 'yesterday'){
            date = new Date(Date.now() - 864e5); // 昨日
        } else {
            date = new Date(); // 今日
        }
    }

    // console.log(date);

    const yyyy = date.getFullYear();
    let mm = date.getMonth(); // 月份的值要 +1
    let dd = date.getDate();

    if(mm.toString().length == 1 && mm < 9){
        mm = `0${mm + 1}`;
    } else {
        mm += 1;
    }

    if(dd.toString().length == 1){
        dd = `0${dd}`;
    }

    // 格式範例：2020-02-07
    const output = `${yyyy}-${mm}-${dd}`;

    return output;
}


// ----- 計算三天後的日期 ----- //
export function getThreeDayAfter(){

    // 三天後：24*60*60*1000 *3 = 25920000

    const date = new Date(Date.now() + 2592e5);

    let dd = date.getDate();

    return dd * 1; // return Number
}


// ---------- helper: 檢查字串值是否只有數字 ---------- //
export function isNumeric(value) {
    // source: https://stackoverflow.com/a/24457420
    return /^\d+$/.test(value);
}


// ----- 根據經緯度計算兩點之間的距離 ----- //
// source: https://stackoverflow.com/a/21623206
// 這個公式沒有考慮地球橢圓形校正，不過測試結果看起來跟 Google Map 距離計算工具差異不大
export function calcDistance(lat1, lon1, lat2, lon2){

    const p = 0.017453292519943295;    // Math.PI / 180
    const c = Math.cos;
    const a =
    0.5 - c((lat2 - lat1) * p) / 2 + c(lat1 * p) * c(lat2 * p) *
    (1 - c((lon2 - lon1) * p)) / 2;

    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}


// ----- 根據 company id 取得 LargePersonGroup 名稱 ----- //
export function getLargePersonGroupId(comId){
    // 規則：hostname + '-' + companyId
    // PS. hostname 的 . 替換為 -

    // const hostname = window.location.hostname.replace(/\./g, '-');

    // 預設使用正式站的 hostname: alcohol-careexpert-life
    let hostname = 'alcohol-careexpert-life';

    // dev server
    if(/^devalcohol/.test(window.location.hostname)){
        hostname = 'dev' + hostname;
    }

    return `${hostname}-${comId}`;
}


/*
根據 tester_menu 設定值，決定顯示「工號 \ 身分證號 \ 手機號」其中一個欄位，此調整只影響前端介面，所以使用 CSS 來控制 tr / td 的顯示與否，此 function 會在指定的 table dom 加上對應的 className，例如 'tm-1'
在 /css/table.less 中設定相關的 CSS 規則
1. 手機號
2. 工號
3. 身分證號
*/
export function testerMenuTableToggle(comId, managersMenuComs, tableSelector){
    // comId: company id
    // managersMenuComData: managersMenu 中的所有公司資料
    // tableSelector: CSS selector 選擇要作用的 table dom

    // 找出目前的公司資料
    const currCom = managersMenuComs.find((com) => {
        return com.id === parseInt(comId, 10);
    });

    if(currCom){
        const tb = document.querySelector(tableSelector);
        if(tb){
            tb.classList.add(`tm-${currCom.tester_menu}`);
        }
    }
}

// ----- loading 加上自訂訊息 ----- //
export function showLoadingMsg(message){
    const box = document.querySelector('.loading-box');
    if(box){
        box.setAttribute("data-msg", message);
    }
    document.body.classList.add('loading', 'loading-custom');
}

// ----- loading 清除訊息 ----- //
export function hideLoadingMsg(){
    const box = document.querySelector('.loading-box');
    if(box){
        box.removeAttribute("data-msg");
    }
    document.body.classList.remove('loading', 'loading-custom');
}

// ----- 用 dayjs 解析日期時間 ----- //
// stringOnly: 直接返回字串格式的資料
// 如果無法解析，返回 false 或空字串 (根據 stringOnly 設定)
export function parseDatetime(Data, stringOnly = false){

    if(!Data || Data.length < 1){
        if(stringOnly){
            return '';
        } else {
            return false;
        }
    }

    const dayjs = require('dayjs');

    const day = dayjs(Data);

    if(!day.isValid()){
        // 如果無法解析，返回 false
        if(stringOnly){
            return '';
        } else {
            return false;
        }
    }

    // 用在畫面顯示的日期格式 2023/12/16
    const date_display = day.format('YYYY/MM/DD');
    // 用在匯出資料時的日期格式 2023-12-16
    const date_export = day.format('YYYY-MM-DD');
    // 時間 15:40:32
    const time = day.format('HH:mm:ss');
    // 字串 2023-12-16 15:40:32
    const outputString = `${date_export} ${time}`;

    if(stringOnly){
        return outputString;
    }

    const result = {
        date: {
            display: date_display,
            export: date_export
        },
        time: time,
        output: outputString, // 字串格式
        dayObject: day // dayjs object
    }

    return result;
}

// ----- 含時區判斷的時間顯示 ----- //
// clientTZ: client (瀏覽器) 時區
export function timeWithTZ(time, timezone, clientTZ = null){

    const dayjs = require('dayjs');
    const _utc = require('dayjs/plugin/utc');
    const _timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
    dayjs.extend(_utc);
    dayjs.extend(_timezone);

    if(!clientTZ){
        clientTZ = dayjs.tz.guess();
    }

    let day;
    if(timezone){
        day = dayjs(time).tz(timezone);
    } else {
        day = dayjs(time);
        // 如果量測紀錄中沒有時區資訊，就根據 window.countryCode 設定預設值
        switch(window.countryCode){
            case 'TW':
                timezone = 'Asia/Taipei';
                break;
            case 'JP':
                timezone = 'Asia/Tokyo';
                break;
            default:
                // 如果都不符合，則以 TW 為預設值
                // 因為在系統支援跨時區之前的紀錄應該都會是在台灣
                timezone = 'Asia/Taipei';
        }
    }
    // 如果有傳入 timezone 參數的話
    // 輸出的會是該時區的當地日期時間，例如日本當地時間
    const result = {
        date: day.format('YYYY/MM/DD'),
        time: day.format('HH:mm:ss'),
        timezone: timezone,
        diff: (clientTZ === timezone)? false : true
    }

    return result;
}
