import './header.less';

export class Header {

    constructor(wrapper, landing = false){

        this.wrapper = wrapper;
        this.landing = landing; // ladning page 樣式，只需要顯示部分元件

        this.init();
    }

    init(){

        // 元件：重要訊息公告
        const sAtxt = window.langTxt.specialAnnouncement;

        let Html = '';

        if(this.landing){
            // ladning page 樣式，只需要顯示部分元件
            Html =
            `<div class="main-header centerwpr">
                <a class="logo" href="/" data-country="${window.countryCode}"></a>
                <div class="spacer"></div>
                <div class="user-control"></div>
            </div>`;

        } else {

            // 重要訊息公告按鈕
            let sAbtn = '';
            if(sAtxt.display){
                sAbtn =
                `<div id="btnSA" class="sa-title">
                    <div class="icon">
                        <svg><use xlink:href="/assets/sprites/sprites_solid.svg#info"></use></svg>
                    </div>
                    ${sAtxt.title}
                    <svg class="arrow"><use xlink:href="/assets/sprites/sprites_solid.svg#angle-right"></use></svg>
                </div>`;
                this.createSAContent();
            }

            // 軟體下載選單
            // const btnAppDwnWpr =
            // `<a class="btn-control" id="btnAppDwn">
            //     <svg>
            //         <use xlink:href="/assets/sprites/sprites_solid.svg#${lang_app.regular.icon}"></use>
            //     </svg>
            //     ${lang_app.regular.btnTxt}
            //     <div class="overlay"></div>
            //     <div id="appDwnMenu"></div>
            // </a>`;

            Html =
            `<div class="main-header centerwpr">
                <a class="logo" href="/" data-country="${window.countryCode}"></a>
                ${sAbtn}
                <div id="sysAnnowpr"></div>
                <div id="extraBtnWpr"></div>
                <div class="user-control"></div>
            </div>`;
        }

        this.wrapper.innerHTML = Html;

        if(sAtxt.display){
            const btnSA = document.getElementById('btnSA');
            if(!btnSA){
                return;
            }
            btnSA.addEventListener('click', (event) => {
                const box = document.getElementById('SABox');
                box.classList.add('open');
            });
        }

        // const btnAppDwn = document.getElementById('btnAppDwn');
        // if(btnAppDwn){
        //     btnAppDwn.addEventListener('click', (event) => {

        //         const target = event.target;

        //         if(target.id === 'btnAppDwn' && !target.hasAttribute('href')){

        //             if(target.classList.contains('active')){
        //                 target.classList.remove('active');
        //             } else {
        //                 target.classList.add('active');
        //             }
        //         }

        //         // 按下任意連結都是關閉選單
        //         if(target.tagName === 'A' && target.id !== 'btnAppDwn'){
        //             btnAppDwn.classList.remove('active');
        //         }

        //         // overlay 關閉選單
        //         if(target.classList.contains('overlay')){
        //             btnAppDwn.classList.remove('active');
        //         }

        //     });

        // }

    }

    createSAContent(){

        const sAtxt = window.langTxt.specialAnnouncement;

        // 判斷使用者是否看過公告內容
        const readtag = localStorage.getItem('sareadtag20231112');

        const wrapper = document.createElement('div');
        wrapper.id = 'SABox';
        if(readtag === 'read'){
            wrapper.classList.remove('open');
        } else {
            wrapper.classList.add('open');
        }

        let list = '';

        if(sAtxt.length > 1){
            list += '<ul>';
            sAtxt.content.forEach((item) => {
                list += `<li><p>${item}</p></li>`;
            });
            list += '</ul>';
        } else {
            list = `<p>${sAtxt.content[0]}</p>`;
        }

        const html =
        `<div class="box-content">
            <h3 class="sa-title">
                <div class="icon">
                    <svg><use xlink:href="/assets/sprites/sprites_solid.svg#info"></use></svg>
                </div>${sAtxt.title}
            </h3>
            <div class="innerwpr">
                ${list}
                <div id="SABoxClose">${sAtxt.close}</div>
            </div>
        </div>
        `;

        wrapper.innerHTML = html;

        document.body.appendChild(wrapper);

        setTimeout(() => {
            localStorage.setItem('sareadtag20231112', 'read');
        }, 0);

        wrapper.addEventListener('click', (event) => {

            if(event.target.id === 'SABoxClose'){
                wrapper.classList.remove('open');
            }

        });
    }

}