import './nav.less';

// 主選單設定檔
import {navSetting} from '../../setting/nav';

export class Nav {

    constructor(wrapper, role, managerID){
        /* 
        wrapper: document.querySelector('.nav-inner')    
        role: 目前作用中身份的英文代碼 ex: com，用來判斷哪些項目需要顯示
        managerID: 目前作用中身份的 manager id
        */        
        this.wrapper = wrapper; // document.querySelector('.nav-inner')
        this.role = role;
        this.managerID = managerID;

        this.init();
    }

    async init(){     

        const navTxt = window.langTxt.nav;   
        
        // 保留此 className 因為在 common.less 中有相關的樣式設定 (待移除)
        document.body.classList.add('side-collapse');
  

        let Html = '';
        
        // 顯示目前作用中權限及所屬站點
        const roleInfo = window.mainUser.userRoleInfo;     

        // 目前作用中權限的主選單設定
        let menuSetting = null;
        if(roleInfo.menu && roleInfo.menu.length > 0){
            menuSetting = roleInfo.menu;
        }        

        const currentPage = window.page;      

        const currLang = window.currLang;

        navSetting.forEach((item) => {

            const permission = item.permission[currLang];        

            // 符合權限的話才顯示此選單項目
            if(permission.includes(this.role)){
                
                if(menuSetting && menuSetting.includes(item.code)){
                    return;
                }

                // 名稱，ex: 資訊統計
                const title = navTxt[item.code];

                // 選單 icon
                let icon = '';
                if(item.icon){
                    icon = 
                    `<svg class="nav-icon ${item.icon}">
                        <use xlink:href="/assets/sprites/sprites_solid.svg#${item.icon}"></use>
                    </svg>`;
                }

                // 網址
                const url = `/panel/${this.managerID}${item.url}`;      

                // current 標示
                let current = '';

                if(currentPage == item.class){

                    current = ' current';

                    // 麵包屑標示
                    document.querySelector('.breadcrumb .current-page')
                        .textContent = title;
                }          
   
                Html += 
                `<a class="nav-entry ${item.class}${current}" href="${url}">
                    ${icon}<span class="text">${title}</span>
                </a>`;
            }
        });        

        this.wrapper.innerHTML = Html;
    }
    
}