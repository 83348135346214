import './comPicker.less';

import _orderBy from 'lodash/orderBy';
import _groupBy from 'lodash/groupBy';

export class comPicker {

    constructor(comsData, container){

        this.comsData = comsData;
        this.container = container;
 
        this.pickComId = null; // 選取的公司 id
        this.pickComType = null; // 選取的公司類型 (types 值)
        this.pickComName = null; // 選取的公司名稱 (全名)

        // 用來篩選的 key 值
        this.filterKey = [
            'id', 'name', 'sname', 'business_no'
        ]

        this.init();        
    }

    /* ----- getter: 取得目前選定的公司 id ----- */
    // ex: DurComMenu.pick
    get pick(){

        const Data = {
            comId: this.pickComId,
            types: this.pickComType,
            name: this.pickComName
        }

        return Data;
    }

    init(){

        // 整理公司資料，將公司資料區分為經銷商與非經銷商，按照 id 由大到小排序
        const Data = _groupBy(this.comsData, (item) => {
            return item.distributor_id === null;
        });

        // 經銷商的資料
        let comsDistData = [];

        // 非經銷商的資料
        let comsOtherData = [];

        // 經銷商資料需要排除 types !== 2 的公司 (只保留經銷商本身)
        if(Data['false'] && Data['false'].length > 1){

            const data = _groupBy(Data['false'], (item) => {
                return item.types === 2;
            });

            // 取得的經銷商資料為 data['true']
            // 再按照 id 由大到小排序
            comsDistData = _orderBy(data['true'], 'id', 'desc');
        }

        // 非經銷商的資料為 Data['true']，按照 id 由大到小排序       
        if(Data['true'] && Data['true'].length > 1){
            comsOtherData = _orderBy(Data['true'], 'id', 'desc');
        }

        // console.log(comsDistData);
        // console.log(comsOtherData);

        // 檢查數量，如果只有一間經銷商且沒有其他公司，那就不顯示選單        
        if(comsDistData.length === 1 && comsOtherData.length === 0){           
            
            const comData = comsDistData[0];            

            this.pickComId = parseInt(comData.id, 10);
            this.pickComType = parseInt(comData.types, 10);
            this.pickComName = comData.name;

            this.container.innerHTML = 
            `<div class="picker-label disabled">${comData.name}<div>`;

            return;
        }        

        let columnCount = 0;
        if(comsDistData.length > 0){
            columnCount += 1;
        }
        if(comsOtherData.length > 0){
            columnCount += 1;
        }

        const html = 
        `<div class="picker-overlay"></div>

        <div class="picker-label">
            <span class="picker-label-txt">請選擇公司</span>
            <svg>
                <use xlink:href="/assets/sprites/sprites_solid.svg#angle-down"></use>
            </svg>
        </div>
        
        <div class="picker-content col-${columnCount}">
            <div class="filter">
                <input class="filterkey" placeholder="搜尋公司名稱、統編或 ID" />
                <svg class="icon-search">
                    <use xlink:href="/assets/sprites/sprites_solid.svg#search"></use>
                </svg>
                <div class="resetInput">
                    <svg>
                        <use xlink:href="/assets/sprites/sprites_solid.svg#times"></use>
                    </svg>
                </div>
            </div>
            <div class="picker-dropdown">
                <p class="menu-msg"></p>
                <div class="menu-wrapper">
                    ${this.menuContent(comsDistData, '經銷商')}
                    ${this.menuContent(comsOtherData, '其他')}
                </div>
            </div>
        </div>`;

        this.container.innerHTML = html;

        this.events();
    }

    menuContent(Data, title){

        let html = '';

        if(Data.length < 1){
            return html;
        }

        let list = '';

        Data.forEach((item) => {

            // 根據 filterKey 紀錄對應的值
            // ex: data-name="${item.name}" etc.  
            let data = '';
            this.filterKey.forEach((key) => {
                data += ` data-${key}="${(item[key])? item[key] : ''}"`;
            });

            list +=
            `<li class="filter-item"${data} data-types="${item.types}">
                <div class="menu-item type-${item.types}" data-id="${item.id}">${item.name}</div>
            </li>`
        });

        html = 
        `<div class="menu-slot">
            <h4 class="title">
                <span class="text">${title}</span>
                <span class="length">(${Data.length})</span>
            </h4>
            <ul>${list}</ul>
        </div>`;

        return html;
    }

    /* ----- 關閉選單 ----- */
    closeMenu(){
        const container = this.container;       

        this.resetFilter();

        // 捲軸捲動到頂端
        container.querySelector('.picker-dropdown').scroll(0, 0);

        container.classList.remove('expand');
    }

    /* ----- 清空 filter input 並恢復顯示所有選項 ----- */
    resetFilter(){
        const container = this.container;

        // 清空 filter input
        container.querySelector('.filterkey').value = '';

        // 顯示所有的選項
        const items = container.querySelectorAll('.filter-item');
        items.forEach((item) => {
            item.classList.remove('hide'); 
        });
    }

    /* ----- 設定 label 的文字 ----- */
    // 如果不傳入參數，則將 label 文字恢復為預設值
    setLabel(text = null){
        const label = this.container.querySelector('.picker-label-txt')
        if(text){
            label.textContent = text;
        } else {
            label.textContent = '請選擇公司';
        }
    }

    events(){

        this.container.addEventListener('click', (event) => {     

            if(event.target.classList.contains('picker-label')){        
                const container = this.container;
                if(container.classList.contains('expand')){
                    // 關閉選單              
                    this.closeMenu();
                } else {
                    // 開啟選單             
                    container.classList.add('expand');
                }
            }

            if(event.target.classList.contains('picker-overlay')){          
                // 關閉選單        
                this.closeMenu();
            }

            // 選擇了某間公司
            if(event.target.classList.contains('filter-item')){
                const target = event.target;

                this.pickComId = parseInt(target.dataset.id, 10);
                this.pickComType = parseInt(target.dataset.types, 10);
                this.pickComName = target.dataset.name;
                this.setLabel(target.dataset.name);
                this.closeMenu();
            }

            // reset filter
            if(event.target.classList.contains('resetInput')){
                this.resetFilter();
            }

        });     

        // 關鍵字篩選
        this.container.querySelector('.filterkey').addEventListener('input', (event) => {
            this.filterItem(event.target.value);
        });    

    }

    /* ----- 關鍵字篩選 ----- */
    filterItem(userInput){

        const items = this.container.querySelectorAll('.filter-item');

        if(userInput.length < 1){
            items.forEach((item) => {
                item.classList.remove('hide'); 
            });             
            return;
        }

        userInput = userInput.toLowerCase();

        items.forEach((item) => {

            let itemMatched = false;

            this.filterKey.forEach((key) => {
                
                let itemValue = item.dataset[key];
                itemValue = itemValue.toLowerCase();

                if(itemValue.includes(userInput)){
                    itemMatched = true;
                }
            });

            if(itemMatched){
                item.classList.remove('hide');             
            } else {
                item.classList.add('hide');
            }

        });

    }

}