// CSS 樣式設定在 common.less 中

/* ----- 顯示操作回饋訊息，在指定時間後自動關閉 ----- */
// type: 'success' || 'fail' 可指定不同的 css 樣式
// 預設 3000ms 後自動關閉
// 可重複呼叫多次，產生多筆訊息
export function showFeedbackMsg(text, type = 'success' , time = 3000){

    let icon = 'check';

    // 錯誤訊息的停留時間多一點
    if(type === 'fail'){
        if(time !== 'permanent'){
            time += 2000;
        }
        icon = 'times';
    }

    const container = document.getElementById('feedbackMsg');

    const message = document.createElement('p');

    message.innerHTML =
    `<div class="icon"><svg><use xlink:href="/assets/sprites/sprites_solid.svg#${icon}"></use></svg></div>${text}`;

    message.className = `show ${type}`;

    container.appendChild(message);

    // 永久不關閉
    if(time === 'permanent'){
        return;
    }

    // 指定時間後關閉訊息
    setTimeout(() => {
        message.classList.remove('show');
    },time);

    // 稍微延遲一段時間後清除內容
    const clearTime = time + 300;

    setTimeout(() => {
        container.removeChild(message);
    },clearTime);

}

