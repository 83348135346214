import './footer.less';

export class Footer {

    constructor(wrapper){ 

        this.wrapper = wrapper;    
        this.init();
    }

    init(){

        const lang_footer = window.langTxt.footer;
        const lang_privacy = window.langTxt.privacy;

        // get current year
        const currentTime = new Date();
        const year = currentTime.getFullYear();

        let linkList = '';

        // terms and privacy
        lang_privacy.forEach((item) => {
            linkList += this.link(item);
        });

        // footer link    
        lang_footer.links.forEach((item) => {
            linkList += this.link(item);
        });

        const links = 
        `<ul class="links">
            ${linkList}
        </ul>`;

        // footer content
        const Html = 
        `<div class="main-footer centerwpr">
            <div class="group">    
                <p>&copy; ${year} ${lang_footer.title}</p>
                <a class="email" href="mailto:${lang_footer.email}">
                    <svg>
                        <use xlink:href="/assets/sprites/sprites_solid.svg#envelope"></use>
                    </svg>
                    ${lang_footer.email}
                </a>
            </div>
            ${links}
        </div>`;

        this.wrapper.innerHTML = Html;

    }

    link(data){

        const target = (data.newTab)? ' target="_blank"' : '';

        const Html = 
        `<li>
            <a href="${data.link}"${target}>${data.title}</a>
        </li>`;

        return Html;
    }


}