import {Nav} from '../../components/nav/nav';
import {Footer} from '../../components/footer/footer';

// <main></main> 放置內容區塊的容器
const mainBlock = document.querySelector('main');

// 未登入時顯示的 footer
export function unauthFooter(){
    const footerWrapper = document.querySelector('footer.footer');
    if(footerWrapper){
        const mainFooter = new Footer(footerWrapper);
    }
}

/* ----- 使用者登入後的基本 HTML 結構，包含主選單 ----- */
export function createContainer(roleCode, roleId){

    htmlMain(); // 建立內容區塊，含 sidebar 與各頁面內容等
    htmlPageHeader(); // 建立 page header

    // 建立 Nav 主選單
    const mainNav = new Nav(
        document.querySelector('.nav-inner'),
        roleCode, // 目前作用中身份的英文代碼
        roleId    // 目前作用中身份的 manager id
    );
}

// 建立主選單與內容區塊 into <main>
function htmlMain(){

    // 主選單
    const sidebar = document.createElement('div');
    sidebar.classList.add('sidebar');

    // terms and privacy 頁面連結
    let links = '';
    window.langTxt.privacy.forEach((data) => {

        const target = (data.newTab)? ' target="_blank"' : '';

        links +=
        `<a href="${data.link}"${target}>${data.title}</a>`;
    });

    sidebar.innerHTML =
    `<div class="sidebar-nav">
        <div class="nav-inner"></div>
    </div>
    <div class="sidebar-footer">
        ${links}
    </div>`;

    // 內容區塊
    const container = document.createElement('article');
    container.classList.add('main');

    container.innerHTML =
    `<div class="page-header"></div>
     <div class="article-content"></div>`;

    // DOM append
    mainBlock.appendChild(sidebar);
    mainBlock.appendChild(container);
}


// page header 的基本 HTML
function htmlPageHeader(){

    const pageHeader = document.querySelector('.page-header');

    // 麵包屑的分隔符號
    const spacer =
    `<svg class="spacer">
        <use xlink:href="/assets/sprites/sprites_solid.svg#angle-right"></use>
    </svg>`;

    const homeIcon =
    `<div class="icon"></div>`;

    // 麵包屑文字
    const breadText =
    `<a class="home" href="/">${homeIcon}</a>${spacer}<span class="current-page"></span>`;

    // 包含 breadcrumb 與 page control (可以放置各頁面的控制項目)
    pageHeader.innerHTML =
    `<div class="breadcrumb">${breadText}</div>
    <div class="page-control unavailable"></div>`;
}


/* ----- 空白內容提示 ----- */
export function emptyHint(title = 'default', text = 'default', icon = 'search'){

    const noContentTxt = window.langTxt.template.noContent;

    if(title === 'default'){
        title = noContentTxt.msg;
    }
    if(text === 'default'){
        text = noContentTxt.tips;
    }

    /*
    可設定標題、提示文字與提示文字前的 icon
    例如：emptyHint('無公司資料', '目前尚無資料', 'pen');
    */
    let svgIcon = '';
    if(icon != null){
        svgIcon =
        `<svg class="hint">
            <use xlink:href="/assets/sprites/sprites_solid.svg#${icon}"></use>
        </svg>`;
    }

    const hintHtml =
    `<div class="empty-hint">
        <div class="innerwpr">
            <h4>${title}</h4>
            <p>${svgIcon}${text}</p>
        </div>
    </div>`;

    const container = document.querySelector('.article-content');
    const listContainer = document.querySelector('#listContainer');

    if(listContainer || window.page === 'panel-coms' || window.page === 'panel-sites'){
        // 有 fastdial 的版型
        container.innerHTML =
        `<div id="fdContainer"></div>
        <div id="listContainer">${hintHtml}</div>`;

    } else {
        container.innerHTML = hintHtml;
    }

    container.classList.remove('unavailable');
}

// ----- 插圖空白內容提示 ----- //
export function emptyIllustrationHint(textContent = '', error = false, container = null){
    // error: 將訊息設定為灰底樣式 (錯誤訊息)

    if(!container){
        container = document.querySelector('.article-content');
    }

    let text = '';
    if(textContent.length > 0){

        const errorStyle = (error)? ' class="error"' : '';

        text = `<p${errorStyle}>${textContent}</p>`;
    }

    const hintHtml =
    `<div class="empty-hint illustration">
        ${text}
    </div>`;

    container.innerHTML = hintHtml;
    container.classList.remove('unavailable');
}



