// 語言別 tc, jp, en
/* ----- 主選單設定 ----- */
export const navSetting = [        
    {
        // 公司列表
        code: 'coms',
        // 可看到此項目的管理權限   
        permission: {
            tc: ['cem', 'dm', 'com'],
            jp: ['cem', 'dm', 'com'],
            en: ['cem', 'dm', 'com']
        },
        icon: 'receipt',                  // Font Awesome icon 代碼 
        url: '/coms',                     // 連結網址
        class: 'panel-coms'               // 在 HTML 加上此 class name
    },
    {
        // 站點列表
        code: 'sites',    
        permission: {
            tc: ['cem', 'dm', 'com', 'sm'],
            jp: ['cem', 'dm', 'com', 'sm'],
            en: ['cem', 'dm', 'com', 'sm']
        },
        icon: 'map-marker-alt',
        url: '/sites',
        class: 'panel-sites'
    },
    {
        // APP 用戶端
        code: 'edges',    
        permission: {
            tc: ['cem', 'dm'],
            jp: ['cem', 'dm'],
            en: ['cem', 'dm']
        },
        icon: 'mobile-alt',
        url: '/edges',
        class: 'panel-edges'
    },
    {
        // 裝置管理
        code: 'devices',   
        permission: {
            tc: ['cem', 'dm', 'com', 'sm'],
            jp: ['cem', 'dm', 'com', 'sm'],
            en: ['cem', 'dm', 'com', 'sm']
        },
        icon: 'capsules',
        url: '/devices',
        class: 'panel-devices'
    },
    {
        // 管理權限表
        code: 'managers',   
        permission: {
            tc: ['cem', 'dm', 'com', 'sm'],
            jp: ['cem', 'dm', 'com', 'sm'],
            en: ['cem', 'dm', 'com', 'sm']
        },
        icon: 'user-tie',
        url: '/managers',
        class: 'panel-managers'
    },
    {
        // 受測者列表
        code: 'testers',    
        permission: {
            tc: ['cem', 'dm', 'com', 'sm'],
            jp: ['cem', 'dm', 'com', 'sm'],
            en: ['cem', 'dm', 'com', 'sm']
        },
        icon: 'user',
        url: '/testers',
        class: 'panel-testers'
    },    
    {
        // 紀錄查詢
        code: 'records',     
        permission: {
            tc: ['cem', 'dm', 'com', 'sm'],
            jp: ['cem', 'dm', 'com', 'sm'],
            en: ['cem', 'dm', 'com', 'sm']
        },
        icon: 'wind',
        url: '/records',
        class: 'panel-records'
    },
    {
        // 檢核表
        code: 'inspection',     
        permission: {
            tc: ['cem'],
            jp: ['cem', 'dm', 'com', 'sm'],
            en: ['cem']
        },
        icon: 'tasks',
        url: '/inspection',
        class: 'panel-inspection'
    },
    {
        code: 'info',
        permission: {
            tc: ['cem', 'dm', 'com', 'sm'],
            jp: ['cem', 'dm', 'com', 'sm'],
            en: ['cem', 'dm', 'com', 'sm']
        },
        icon: 'cube',
        url: '',
        class: 'dashboard'
    }
    // {
    //     // 資訊面板 (舊的資訊統計頁，顯示裝置狀態總覽)
    //     code: 'info',     
    //     permission: ['cem', 'dm', 'com', 'sm', 'tm'],
    //     icon: 'database',
    //     url: '',
    //     class: 'panel-info'
    // },    
]